import axios from "axios";
import { singleton, injectable } from "tsyringe";
import { RtcMediaStreamWrapper, DeviceInfo, DeviceStreamManager, MultiCastDelegate } from "ui-gallery";
import { ConnectionSetting } from "@/models/connection/ConnectionSetting";
import { ScreenShareRoom } from "@/models/webRtc/ScreenShareRoom";
import { VideoChatConnection } from "@/models/webRtc/VideoChatRoom";
import { StreamInfo } from "@/models/webRtc/StreamInfo";
import { IVideoChatConnection } from "./IVideoChatConnection";

/**
 * WebRTCに関するサービスを提供します.
 */
@injectable()
export class WebRtcService {
    // #regoin private fields
    private _transmitStream: RtcMediaStreamWrapper = new RtcMediaStreamWrapper(new MediaStream(), false);
    private _screenShareTransmitStream = new RtcMediaStreamWrapper(new MediaStream(), false);
    private _videoChatConnection: IVideoChatConnection | null = null;
    private _screenShareConnection: ScreenShareRoom | null = null;
    private _connectionSetting = new ConnectionSetting();
    private _videoDevices: DeviceInfo[] = [];
    private _outputDevices: DeviceInfo[] = [];
    private _audioDevices: DeviceInfo[] = [];
    // #endregion

    public streams: { [key: string]: StreamInfo } = {};

    public readonly streamsChanged = new MultiCastDelegate<(streams: { [key: string]: StreamInfo }) => void>();
    public readonly enter = new MultiCastDelegate<(peerId: string) => void>();
    public readonly left = new MultiCastDelegate<(peerId: string) => void>();
    // #region public getters
    /**
     * ビデオチャット送信用のStream
     */
    public get transmitStream(): RtcMediaStreamWrapper {
        return this._transmitStream;
    }

    /**
    * 画面共有送信用のStream
    */
    public get screenShareTransmitStream(): RtcMediaStreamWrapper {
        return this._screenShareTransmitStream;
    }

    /**
     * @sumary ビデオチャットの接続コンテキスト
     */
    public get videoChatConnection(): IVideoChatConnection | null {
        return this._videoChatConnection;
    }

    /**
     * @sumary 画面共有の接続コンテキスト
     */
    public get screenShareConnection(): ScreenShareRoom | null {
        return this._screenShareConnection;
    }

    /**
     * ビデオデバイス一覧
     */
    public get videoDevices(): DeviceInfo[] {
        return this._videoDevices;
    }

    /**
     * オーディオデバイス一覧
     */
    public get audioDevices(): DeviceInfo[] {
        return this._audioDevices;
    }

    /**
     * アウトプットデバイス一覧
     */
    public get outputDevices(): DeviceInfo[] {
        return this._outputDevices;
    }

    /**
     * 接続設定を取得します.
     */
    public get connectionSetting(): ConnectionSetting {
        return this._connectionSetting;
    }
    // #endregion

    // #region public mutaions
    /**
     * @sumary ビデオチャットの接続コンテキストをセットします.
     * @param connection セットするビデオチャットの接続コンテキスト
     */
    public setVideoChatConnection(connection: IVideoChatConnection) {
        this._videoChatConnection = connection;
    }

    /**
     * @sumary 画面共有の接続コンテキストをセットします.
     * @param connection セットするビデオチャットの接続コンテキスト
     */
    public setScreenShareConnection(connection: ScreenShareRoom) {
        this._screenShareConnection = connection;
    }

    /**
     * ビデオチャット送信用のStreamをセットします.
     * @param streamWrapper セットするストリーム
     */
    public setTransmitStream(streamWrapper: RtcMediaStreamWrapper) {
        this._transmitStream = streamWrapper;
    }

    /**
     * ビデオチャット送信用のStreamをセットします.
     * @param streamWrapper セットするストリーム
     */
    public setScreenShareTransmitStream(streamWrapper: RtcMediaStreamWrapper) {
        this._screenShareTransmitStream = streamWrapper;
    }

    /**
     * オーディオデバイス一覧をセットします.
     * @param audioDevices オーディオデバイス一覧
     */
    public setAudioDevices(audioDevices: DeviceInfo[]) {
        this._audioDevices = audioDevices;
    }

    /**
     * ビデオデバイス一覧をセットします.
     * @param videoDevices ビデオデバイス一覧
     */
    public setVideoDevices(videoDevices: DeviceInfo[]) {
        this._videoDevices = videoDevices;
    }

    /**
     * アウトプットデバイス一覧をセットします.
     * @param outputDevices アウトプットデバイス一覧
     */
    public setOutputDevices(outputDevices: DeviceInfo[]) {
        this._outputDevices = outputDevices;
    }

    /**
     * 接続情報をセットします.
     * @param connectionSetting 接続情報
     */
    public setConnectionSetting(connectionSetting: ConnectionSetting) {
        this._connectionSetting = connectionSetting;
    }
    // #endregion

    // #region actions

    /**
     * デバイス情報を更新します
     * @returns 非同期処理
     */
    public async updateDevices(): Promise<void> {
        const devices = await DeviceStreamManager.getDeviceList();
        this.setAudioDevices(devices.audioDeviceList);
        this.setVideoDevices(devices.videoDeviceList);
        this.setOutputDevices(devices.outputDeviceList);
    }
    // #endregion
}

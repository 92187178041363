import { register } from "register-service-worker";

declare var showInstallPromotion: any;
let promptEvent: any = null;

const installed = function (e: any) {
    promptEvent = null;
    console.log("install", e);
};

const beforeinstallprompt = function (e: any) {
    console.log("before install", e);
    promptEvent = e;
    promptEvent.preventDefault();
    showInstallPromotion();

    // if (!localStorage.getItem("installed")) {
    //     install();
    //     localStorage.setItem("installed", "true");
    // }

    return false;
};

window.addEventListener("beforeinstallprompt", beforeinstallprompt);
window.addEventListener("appinstalled", installed);

export function getIsInstalled() {
    return !!promptEvent;
}

export async function install(): Promise<boolean> {
    try {
        if (promptEvent) {
            promptEvent.prompt();
            await promptEvent.userChoice;
            return true;
        }
        else return false;
    }
    catch (ex) {
        console.log("Failed to install app.", ex);
    }
    return false;
};

if (process.env.NODE_ENV === "production") {
    register(`${process.env.BASE_URL}service-worker.js`, {
        ready() {
            console.log(
                "App is being served from cache by a service worker.\n" +
                "For more details, visit https://goo.gl/AFskqB"
            );
        },
        registered() {
            console.log("Service worker has been registered.");
        },
        cached() {
            console.log("Content has been cached for offline use.");
        },
        updatefound() {
            console.log("New content is downloading.");
        },
        updated() {
            location.reload();
        },
        offline() {
            console.log("No internet connection found. App is running in offline mode.");
        },
        error(error) {
            console.error("Error during service worker registration:", error);
        }
    });
}
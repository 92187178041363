<template>
    <div
        class="strech drop-area relative"
        :class="isDragOverring?'drop-area drag-over':''"
        style="cursor:pointer;"
        @dragover.prevent="onDragOver"
        @drop.prevent="drop"
        @dragleave="onDragLeave"
    >
        <div class="absolute-center">
            <v-icon
                :class="isDragOverring?'upload-icon':''"
                style="font-size:72px;margin-top:-20px;"
                color="primary"
            >mdi-cloud-upload-outline</v-icon>
            <div class="mt-4 pa-3" style="font-size:20px;white-space: pre-line;word-break: break-all;" v-text="message"></div>
            <v-btn class="mt-6" color="primary">またはファイルを選択する</v-btn>
        </div>
    </div>
</template>
<script lang="ts">
/**
 * @packageDocumentation
 * @module Components
 * @preferred
 */
import { Vue, Prop, Component, Emit } from "vue-property-decorator";
import { VIcon } from "vuetify/lib";

/**
 * ファイルをドロップして受け取るエリアを提供します．
 */
@Component({ components: { VIcon } })
export default class FileDropArea extends Vue {
    private isDragOverring = false;

    /**
     * ドロップエリアに表示するメッセージ
     */
    @Prop({ default: "ファイルをドロップしてください" }) readonly message!: string;

    /**
     * 画像ファイルがドロップされたときに実行されるイベントハンドラです．
     * @param ドロップされたファイル
     */
    @Emit("dropped")
    // eslint-disable-next-line brace-style
    public dropped(_: File): void { }

    private onDragOver() {
        this.isDragOverring = true;
    }

    /**
     * ファイルがドロップされたとき．
     */
    private onDrop(e: DragEvent) {
        e.preventDefault();
        this.isDragOverring = false;
        if (!e.dataTransfer) return;
        const file = e.dataTransfer.files[0];
        if (!file) return;

        this.dropped(file);
    }

    /**
     * ファイルのドラッグをやめたとき．
     */
    private onDragLeave() {
        this.isDragOverring = false;
    }
}
</script>
<style scoped lang="scss">
.drop-area {
    border: 2px dashed rgba(127, 127, 127, 0.4);
    -webkit-border-radius: 4px;
    border-radius: 4px;
    padding: 48px;
    text-align: center;
    font: 20pt bold arial;
}

.drag-over {
    border: 2px solid rgb(156, 50, 255);
}

.upload-icon {
    animation: vertical 0.4s ease-in-out infinite alternate;
}
@keyframes vertical {
    0% {
        transform: translateY(-20px);
    }
    100% {
        transform: translateY(0px);
    }
}
</style>

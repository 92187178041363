import { AudioInputStream, SpeechConfig, AudioConfig, SpeechRecognizer } from "microsoft-cognitiveservices-speech-sdk";
import Axios from "axios";

const region = "eastus";
const apiKey = "a88b09044f954991b17f88a09ed61176";

export class SpeechToTextService {
    speechConfig: any;
    recognizer?: SpeechRecognizer;
    texts: string[] = [];
    isRunning = false;
    deviceId = "";

    constructor() {

    }

    public async connect(deviceId?: string): Promise<void> {
        if (location.host.includes("stg") || location.host.includes("localhost")) {
            this.deviceId = deviceId ?? this.deviceId;

            this.speechConfig = SpeechConfig.fromSubscription(apiKey, region);
            this.speechConfig.speechRecognitionLanguage = "ja-JP";
            var audioConfig = AudioConfig.fromMicrophoneInput(this.deviceId);
            this.recognizer = new SpeechRecognizer(this.speechConfig, audioConfig);
            this.recognizer.startContinuousRecognitionAsync();
            this.recognizer.recognized = (s, e) => {
                const res = e.result;
                this.texts.push(res.text);
            };
            this.isRunning = true;
        }
    }

    public async disconnect(): Promise<void> {
        await this.recognizer?.stopContinuousRecognitionAsync();
        this.recognizer?.close();
        this.isRunning = false;
    }

    public async switchDevice(deviceId: string): Promise<void> {
        if (location.host.includes("stg") || location.host.includes("localhost")) {
            this.deviceId = deviceId;
            if (!this.isRunning) return;
            await this.disconnect();
            await this.connect(deviceId);
        }
    }
}
/**
 * @description ZEN TALKの管理画面を提供します．
 * @module ZenTalkManagements
 */

import "./installer";
import "reflect-metadata";
import "@/plugins/middleware";

import Vue from "vue";
import managementRouter from "@/router/ManagementRouter";
import vuetify from "@/plugins/vuetify";
import { container } from "tsyringe";
import { interval, from } from "rxjs";

import { DocumentManagementsService } from "@/models/documents/DocumentManagementsService";
import { DocumentEditService } from "@/models/documents/DocumentEditService";
import { HistoryService } from "@/models/histories/HistoryService";

// styleを読み込む． vuetifyよりも後ろに記述
import "ui-gallery/build/build.esm.css";
import "./style.scss";

import { DateTime } from "luxon";
import { DocumentShareService } from "@/models/documents/DocumentShareService";
import { DrawingService } from "@/models/drawing/DrawingService";
import { ChatService } from "./models/chat/ChatService";
import Axios from "axios";
import { SettingService } from "./models/settings/SettingService";
import { RoomsManagementService } from "./models/room/RoomManagementsService";
import { AuthService } from "./models/auth/AuthService";
import { WebSocketService } from "./models/web-socket/WebSocketService";
import { ProfileService } from "./models/profile/ProfileService";
import { RoomInfoService } from "./models/room/RoomInfoService";
import { WebRtcService } from "./models/webRtc/WebRtcService";
import { ConnectionService } from "./models/room/ConnectionService";
import { SpeechToTextService } from "./models/speech-to-text/SpeechToTextService";
import qs from "qs";
import VueRx from "vue-rx";

Vue.use(VueRx);

// コンテナ定義
container.register(AuthService, { useValue: Vue.observable(new AuthService()) })
    .register(HistoryService, { useValue: Vue.observable(new HistoryService()) })
    .register(DocumentEditService, { useValue: Vue.observable(new DocumentEditService()) })
    .register(DocumentManagementsService, { useValue: Vue.observable(new DocumentManagementsService()) })
    .register(RoomsManagementService, { useValue: Vue.observable(container.resolve(RoomsManagementService)) })
    .register(SettingService, { useValue: Vue.observable(new SettingService()) })
    .register(ChatService, { useValue: Vue.observable(new ChatService(container.resolve(WebSocketService))) })
    .register(ProfileService, { useValue: Vue.observable(new ProfileService()) })
    .register(RoomInfoService, { useValue: Vue.observable(new RoomInfoService(container.resolve(HistoryService))) })
    .register(WebRtcService, { useValue: Vue.observable(new WebRtcService()) })
    .register(DocumentShareService, { useValue: Vue.observable(new DocumentShareService(container.resolve(WebSocketService))) })
    .register(ConnectionService, { useValue: Vue.observable(new ConnectionService(container.resolve(WebSocketService))) })
    .register(DrawingService, { useValue: Vue.observable(new DrawingService(container.resolve(WebSocketService), container.resolve(DocumentShareService))) })
    .register(SpeechToTextService, { useValue: Vue.observable(container.resolve(SpeechToTextService)) });

Vue.config.productionTip = false;
Vue.config.silent = true;

const programStartDateTime = DateTime.local();
logger.onErrorHandler = (...e: any) => {
    console.log("ログを送信しました", e);
    const authService = container.resolve(AuthService);
    Axios.post("/api/logging", {
        type: "error",
        message: JSON.stringify({
            programStartDate: programStartDateTime.toString(),
            logDateTime: DateTime.local().toString(),
            time: DateTime.local().diff(programStartDateTime).toString(),
            location: location.pathname,
            memberName: authService.memberName,
            memberId: authService.memberId,
            groupId: authService.currentGroupId,
            data: e,
            ua: navigator.userAgent
        })
    });
};

/**
 * urlのtokenの有無でログイン方法を切り替える（強制ログイン）
 * @param 強制ログイン情報
 */
(async () => {
    const authService = container.resolve(AuthService);
    const params = qs.parse(location.search.substr(1)) as any;

    if(params.token){
        const baseToken = JSON.parse(decodeURIComponent(params.token));
        // 強制ログイン処理
        console.log("強制ログインを行います");
        const res = authService.forceLogin(baseToken);
    }
    else{
        // localから取得
        console.log("通常のログインを行います");
        const loadLocalstorage = authService.loadStorage();
    }
})();

if (!location.pathname.includes("extern")) {
    container.resolve(AuthService).tryRefreshToken().then(() => {
        new Vue({
            vuetify,
            router: managementRouter,
            render: h => h("router-view"),
            created() {
                const isDark = localStorage.getItem("isDark") === "true";
                this.$vuetify.theme.dark = isDark;
            }
        }).$mount("#app");
    });
}
else {
    new Vue({
        vuetify,
        router: managementRouter,
        render: h => h("router-view"),
        created() {
            const isDark = localStorage.getItem("isDark") === "true";
            this.$vuetify.theme.dark = isDark;
        }
    }).$mount("#app");
}

// 30秒に1回経過時間を計測して１時間以上経過していたらページをリフレッシュ
const programBeginTime = DateTime.local();
const subscription = interval(30000).subscribe(() => {
    if (DateTime.local().diff(programBeginTime).milliseconds > 60 * 5 * 60 * 1000) {
        subscription.unsubscribe();
        if (confirm("60分以上経過しているためリロードしてもよろしいですか？")) {
            location.reload();
        }
    }
});

// iosのアドレスバー対策
// body読み込み時に一度だけbodyサイズを設定
document.body.onload = () => {
    document.body.style.minHeight = window.innerHeight + "px";
    document.body.style.maxHeight = window.innerHeight + "px";
    document.body.style.height = window.innerHeight + "px";
};

// リサイズを停止して500ms後にbodyサイズを設定
let timeoutId = 0;
window.addEventListener("resize", () => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
        document.body.style.minHeight = window.innerHeight + "px";
        document.body.style.maxHeight = window.innerHeight + "px";
    }, 500) as any as number;
});

window.addEventListener("dragover", (ev) => {
    ev.preventDefault();
}, false);
window.addEventListener("drop", (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
}, false);

const isStg = location.host.includes("stg");
const isLocal = location.host.includes("localhost") || location.host.includes("192.168.");

export const config = {
    camelFrontUrl: isLocal ? "http://localhost:8082" : (isStg ? "https://cms-stg.scene-live.com" : "https://cms.scene-live.com"),
    camelUrl: isLocal ? "http://localhost:8081" : (isStg ? "https://cms-stg.scene-live.com" : "https://cms.scene-live.com"),
    url: isStg || isLocal ? "https://zen-talk-api-stg.scene-live.com" : "https://zen-talk-api.scene-live.com",
    marlboroUrl: (isStg || isLocal) ? "https://storage-stg.scene-live.com" : "https://storage.scene-live.com",
    connectionPageUrl: isStg || isLocal ? "https://zen-talk-stg.scene-live.com" : "https://zen-talk.scene-live.com",
    jtUrl: isStg ? "https://accounts-stg.scene-live.com/member_management" : "https://accounts.scene-live.com/member_management",
    webSocketUrl: isStg || isLocal ? "wss://ws-stg.scene-live.com/ws" : "wss://ws1.scene-live.com/",
    thanksPage: "https://zen-talk.com/entrance/?thanks=1",
    networkMiddleBps: 8,
    networkLowBps: 3,
    maxConnectionCount: 10,
    lowQualityThreshouldCount: 3
};

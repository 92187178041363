import axios from "axios";
import { IRoomInfo } from "@/models/room/IRoomInfo";
import { RoomInfo } from "@/models/room/RoomInfo";
import { SuccessResponse } from "@/models/api/Response";
import { HistoryService } from "@/models/histories/HistoryService";

/**
 * ルーム情報に関するサービスを提供します.
 */
export class RoomInfoService {
    // #regoin private fields
    private _roomInfo: IRoomInfo | null = null;
    private _historyId = "";
    // #endregion

    // #region public gettters
    /**
     * ルーム情報一覧
     */
    public get roomInfo(): IRoomInfo | null {
        return this._roomInfo;
    }
    // #endregion

    // public mutations
    /**
     * ルーム情報一覧をセットします。
     * @param roomInfo セットするルーム情報一覧
     */
    public setRoomInfo(roomInfo: IRoomInfo) {
        this._roomInfo = roomInfo;
    }
    // #endregion

    public constructor(readonly historyService: HistoryService) {

    }

    public async sendHistory(message: string, detail: string): Promise<boolean> {
        try {
            if (!this._historyId) throw new Error("historyIdが存在しません");
            const response = await axios.post<SuccessResponse<IRoomInfo>>(`/api/connection-histories/${this._historyId}/details`, {
                comment: message,
                detail,
                createdAt: new Date(),
                rating: "0"
            });
            return true;
        }
        catch (ex) {
            logger.error(["接続履歴の送信に失敗しました", ex]);
            console.error("接続履歴の送信に失敗しました");
        }
        return false;
    }

    /**
     * ルーム情報をサーバーから取り込みます.
     * @param key ルームの鍵
     * @return 成功したかどうか
     */
    public async fetchRoomInfo(roomId: number): Promise<boolean> {
        try {
            const response = await axios.get<SuccessResponse<IRoomInfo>>("/api/rooms/" + roomId);
            const historyResponse = await axios.get<SuccessResponse<{ count: Number, histories: { chId: string }[] }>>("/api/connection-histories?roomId=" + response.data.data.roomId);
            this.setRoomInfo(response.data.data);
            if (historyResponse.data.data.count) {
                this._historyId = historyResponse.data.data.histories[0].chId;
            }
            return true;
        }
        catch (ex) {
            if (ex.response.status === 404) {
                logger.error("鍵が間違っている為、ルーム情報の取り込みに失敗しました。", ex);
            }
            else {
                logger.error("ルーム情報の取り込みに失敗しました。", ex);
            }
        }
        return false;
    }

    /**
     * ルーム情報をサーバーから取り込みます.
     * @param key ルームの鍵
     * @return 成功したかどうか
     */
    public async fetchRoomInfoFromNumber(connectionNumber: string): Promise<boolean> {
        try {
            const response = await axios.get<SuccessResponse<{ [key: string]: IRoomInfo }>>("/api/rooms?cn=" + connectionNumber);
            const room = response.data.data[Object.keys(response.data.data)[0]];
            this.setRoomInfo(room);

            const historyResponse = await axios.get<SuccessResponse<{ count: Number, histories: { chId: string }[] }>>("/api/connection-histories?roomId=" + room.roomId, {
                headers: {
                    "X-Zentalk-GroupId": room.groupId
                }
            });
            if (historyResponse.data.data.count) {
                this._historyId = historyResponse.data.data.histories[0].chId;
            }
            return true;
        }
        catch (ex) {
            console.log(ex);
            logger.error("ルーム情報の取り込みに失敗しました。", ex);
        }
        return true;
    }

    /**
     * ルーム情報を更新します.
     * @param roomInfo 作成するルーム情報
     */
    public async updateRoom(roomInfo: Partial<IRoomInfo>): Promise<IRoomInfo | undefined> {
        try {
            const room = Object.assign(this.roomInfo, roomInfo) as any;
            room.slot = undefined;
            const response = await axios.put<SuccessResponse<any>>("/api/rooms/" + roomInfo.roomId, room);
            const updated = response.data.data;
            if (this.roomInfo) {
                this.roomInfo.currentDocId = roomInfo.currentDocId;
            }
            return response.data.data;
        }
        catch (ex) {
            logger.error("ルームの更新に失敗しました。", ex);
        }
        return undefined;
    }

    /**
     * ルーム情報を削除します.
     * @param key ルームの鍵
     * @return 成功したかどうか
     */
    public async deleteRoom(roomId: number): Promise<boolean> {
        try {
            const response = await axios.delete<SuccessResponse<any>>("/api/rooms/" + roomId);
            this.sendHistory("接続を終了しました", "システム");
            return true;
        }
        catch (ex) {
            console.error("ルーム情報の取り込みに失敗しました。", ex);
            if (ex.response.status === 404) {
                logger.error("鍵が間違っている為、ルーム情報の取り込みに失敗しました。", ex);
            }
            else {
                logger.error("ルーム情報の取り込みに失敗しました。", ex);
            }
        }
        return false;
    }
}

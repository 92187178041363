import Vue from "vue";
import "vuetify/dist/vuetify.min.css";
import ja from "vuetify/src/locale/ja";
import Vuetify, { colors } from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {
            customProperties: true
        },
        themes: {
            dark: {
                primary: "#9f89ff",
                secondary: colors.lightBlue.lighten2,
                accent: colors.grey.darken2,
                error: colors.red.base,
                info: colors.grey.lighten2,
                success: colors.lightGreen.base
            },
            light: {
                primary: "#6d5db3",
                secondary: colors.lightBlue.lighten2,
                accent: colors.grey.darken2,
                error: colors.red.base,
                info: "#fbfbfb",
                success: colors.lightGreen.base
            }
        }
    },
    lang: {
        locales: { ja },
        current: "ja"
    },
    icons: {
        iconfont: "mdi"
    }
});

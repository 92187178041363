import { LayerBase } from "./LayerBase";
import Konva from "konva";
import { v4 } from "uuid";
export class TextLayer extends LayerBase {
    // #region private fields
    private _fontSize = 12;
    private srcShapeX = 0;
    private srcShapeY = 0;
    // #endregion

    // region public properties
    public get color(): string {
        return this._color;
    }
    public set color(color: string) {
        if (LayerBase.currentShape) {
            LayerBase.currentShape.fill(color);
            LayerBase.currentShape.draw();
        }
        this._color = color;
    }

    public get fontSize(): number {
        return this._fontSize;
    }
    public set fontSize(fontSize: number) {
        if (LayerBase.currentShape) {
            const text = LayerBase.currentShape as Konva.Text;
            text.fontSize(fontSize);
            text.draw();
        }
        this._fontSize = fontSize;
    }

    // #endregion

    // #region public methods
    public update(x: number, y: number) {
        super.update(x, y);

        if (!LayerBase.isDrawing || !LayerBase.currentShape) return;
        const shape = (LayerBase.currentShape as Konva.Rect);

        const width = x - this.srcShapeX;
        if (width < 0) {
            shape.width(Math.max(Math.abs(width), 40));
            shape.x(this.srcShapeX + width);
        }
        else {
            shape.width(Math.max(width, 40));
        }

        const height = y - this.srcShapeY;
        if (height < 0) {
            shape.height(Math.max(Math.abs(height), 40));
            shape.y(this.srcShapeY + height);
        }
        else {
            shape.height(Math.max(height, 40));
        }
        this.layer.draw();
    }

    public onEndDrawing() {
        if (!LayerBase.isDrawing) return;
        LayerBase.isDrawing = false;

        const currentShape = LayerBase.currentShape as Konva.Text;
        if (!currentShape) return;
        currentShape.on("mouseover", e => {
            LayerBase.isDragging = true;
        });
        currentShape.on("mouseout", e => {
            LayerBase.isDragging = false;
        });
        currentShape.on("transformstart", e => {
            LayerBase.isDragging = true;
        });
        currentShape.on("transformend", e => {
            LayerBase.isDragging = false;
        });
        currentShape.on("mousedown", e => {
            LayerBase.currentShape = e.target as Konva.Shape;
            this.transformer.attachTo(e.target);
            LayerBase.isDragging = true;
            this.layer.draw();
        });

        currentShape.on("dblclick", () => {
            // hide text node and transformer:
            currentShape.hide();
            this.transformer.hide();
            this.layer.draw();

            // create textarea over canvas with absolute position
            // first we need to find position for textarea
            // how to find it?

            // at first lets find position of text node relative to the stage:
            const textPosition = currentShape.getAbsolutePosition();

            // then lets find position of stage container on the page:
            const stageBox = this.stage.container().getBoundingClientRect();

            // so position of textarea will be the sum of positions above:
            const areaPosition = {
                x: stageBox.left + textPosition.x,
                y: stageBox.top + textPosition.y
            };

            // create textarea and style it
            const textarea = document.createElement("textarea");
            document.body.appendChild(textarea);

            // apply many styles to match text on canvas as close as possible
            // remember that text rendering on canvas and on the textarea can be different
            // and sometimes it is hard to make it 100% the same. But we will try...
            textarea.value = currentShape.text() === "クリックして入力" ? "" : currentShape.text();
            textarea.style.position = "absolute";
            textarea.style.top = areaPosition.y + "px";
            textarea.style.left = areaPosition.x + "px";
            textarea.style.width = currentShape.width() - currentShape.padding() * 2 + "px";
            textarea.style.height =
                currentShape.height() - currentShape.padding() * 2 + 5 + "px";
            textarea.style.fontSize = currentShape.fontSize() + "px";
            textarea.style.border = "1px solid blue";
            textarea.style.padding = "4px";
            textarea.style.margin = "0px";
            textarea.style.overflow = "hidden";
            textarea.style.background = "none";
            textarea.style.outline = "none";
            textarea.style.resize = "none";
            textarea.style.lineHeight = currentShape.lineHeight().toString();
            textarea.style.fontFamily = currentShape.fontFamily();
            textarea.style.transformOrigin = "left top";
            textarea.style.textAlign = currentShape.align();
            textarea.style.color = currentShape.fill();
            const rotation = currentShape.rotation();
            let transform = "";
            if (rotation) {
                transform += "rotateZ(" + rotation + "deg)";
            }

            let px = 0;
            // also we need to slightly move textarea on firefox
            // because it jumps a bit
            const isFirefox =
                navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
            if (isFirefox) {
                px += 2 + Math.round(currentShape.fontSize() / 20);
            }
            transform += "translateY(-" + px + "px)";

            textarea.style.transform = transform;

            // reset height
            textarea.style.height = "auto";
            // after browsers resized it we can set actual value
            textarea.style.height = textarea.scrollHeight + 3 + "px";

            textarea.focus();
            LayerBase.isDragging = true;

            const removeTextarea = () => {
                if (!textarea || !textarea.parentNode) return;
                textarea.parentNode.removeChild(textarea);
                window.removeEventListener("click", handleOutsideClick);
                currentShape.show();
                this.transformer.show();
                this.transformer.forceUpdate();
                this.layer.draw();
                LayerBase.isDragging = false;
            };

            function setTextareaWidth(newWidth: number) {
                if (!newWidth) {
                    // set width for placeholder
                    // newWidth = currentShape.placeholder.length * currentShape.fontSize();
                }
                // some extra fixes on different browsers
                const isSafari = /^((?!chrome|android).)*safari/i.test(
                    navigator.userAgent
                );
                const isFirefox =
                    navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
                if (isSafari || isFirefox) {
                    newWidth = Math.ceil(newWidth);
                }
                textarea.style.width = newWidth + "px";
            }

            textarea.addEventListener("keydown", function (e) {
                // hide on enter
                // but don't hide on shift + enter
                if (e.keyCode === 13 && !e.shiftKey) {
                    currentShape.text(textarea.value || "クリックして入力");
                    removeTextarea();
                }
                // on esc do not set value back to node
                if (e.keyCode === 27) {
                    removeTextarea();
                }
            });

            textarea.addEventListener("keydown", function (e) {
                const scale = currentShape.getAbsoluteScale().x;
                setTextareaWidth(currentShape.width() * scale);
                textarea.style.height = "auto";
                textarea.style.height =
                    textarea.scrollHeight + currentShape.fontSize() + "px";
            });
            function handleOutsideClick(e: any) {
                if (e.target !== textarea) {
                    currentShape.text(textarea.value || "クイックして入力");
                    removeTextarea();
                }
            }
            setTimeout(() => {
                window.addEventListener("click", handleOutsideClick);
            });
        });
    }

    protected createShape(x: number, y: number) {
        const text = new Konva.Text({
            id: v4(),
            x,
            y,
            draggable: true,
            text: "クリックして入力",
            fontSize: 30,
            fontFamily: "Yu Gothic",
            fill: this.color
        });

        this.srcShapeX = x;
        this.srcShapeY = y;
        this.transformer.attachTo(text);
        return text;
    }
    // #endregion
}

import { LayerBase } from "./LayerBase";
import Konva from "konva";
import { v4 } from "uuid";
export class LineLayer extends LayerBase {
    public update(x: number, y: number) {
        super.update(x, y);

        if (!LayerBase.isDrawing || !LayerBase.currentShape) return;
        (LayerBase.currentShape as Konva.Line).points().push(
            x,
            y
        );
        this.layer.draw();
    }

    protected createShape(x: number, y: number) {
        return new Konva.Line({
            id: v4(),
            points: [x, y],
            stroke: this.color,
            strokeWidth: this.strokeWidth,
            lineCap: "round",
            lineJoin: "round",
            draggable: true
        });
    }
}

<template>
    <div
        v-ripple
        class="strech drop-area d-flex flex-column justify-center align-center relative"
        :class="'dot-line'"
        style="cursor:pointer;"
        :style="isDragOverring?'border:2px solid '+color:''"
        @dragover.prevent="onDragOver"
        @drop.prevent="onDrop"
        @dragleave="onDragLeave"
        @click="onClick"
    >
        <img
            style="position:absolute;height:100%;width:100%;background:transparent;"
            :style="'object-fit:'+posterObjectFit"
            v-if="poster"
            :src="poster"
        />
        <v-icon
            :style="{opacity:poster?0.4:1}"
            :class="isDragOverring?'upload-icon':''"
            style="font-size:64px;margin-top:0px;"
            color="primary"
        >mdi-cloud-upload-outline</v-icon>
        <div
            class="px-3"
            v-if="!poster"
            :style="{fontSize}"
            style="word-break: break-word;font-weight:600;"
            v-html="message"
        ></div>
        <input
            ref="fileInput"
            type="file"
            v-show="false"
            @change="onFileRecieved"
        />
    </div>
</template>
<script lang="ts">
/**
 * @packageDocumentation
 * @module Components
 * @preferred
 */
import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import { VIcon } from "vuetify/lib";

/**
 * ファイルをドロップして受け取るエリアを提供します．
 */
@Component({ components: { VIcon } })
export default class FileDropAreaCompact extends Vue {
    private isDragOverring = false;

    /**
     * ドロップエリアに表示するメッセージ
     */
    @Prop({ default: "ファイルをドロップしてください" }) readonly message!: string;

    /**
     * ドロップエリアに表示するメッセージ
     */
    @Prop({ default: null }) readonly poster!: string | null;

    /**
     * アイコンのカラー
     */
    @Prop({ default: "rgb(156, 50, 255)" }) readonly color!: string;

    /**
     * アイコンのカラー
     */
    @Prop({ default: "cover" }) readonly posterObjectFit!: "cover" | "contain";

    /**
     * 画像ファイルがドロップされたときに実行されるイベントハンドラです．
     * @param ドロップされたファイル
     */
    @Emit("dropped")
    // eslint-disable-next-line brace-style
    public dropped(_: File): void { }

    @Prop({ default: "20px" }) readonly fontSize!: string;

    private onDragOver() {
        this.isDragOverring = true;
    }

    /**
     * ファイルがドロップされたとき．
     */
    private onDrop(e: DragEvent) {
        e.preventDefault();
        this.isDragOverring = false;
        if (!e.dataTransfer) return;
        const file = e.dataTransfer.files[0];
        if (!file) return;

        this.dropped(file);
    }

    /**
     * クリックされたとき．
     * @param e クリックイベント
     */
    private onClick(e: MouseEvent) {
        const input = this.$refs.fileInput as HTMLElement | undefined;
        if (!input) return;
        input.click();
    }

    private onFileRecieved(e: InputEvent) {
        if (e.target) {
            const [file] = (e.target as any).files as File[];
            this.dropped(file);
        }
    }

    /**
     * ファイルのドラッグをやめたとき．
     */
    private onDragLeave() {
        this.isDragOverring = false;
    }
}
</script>
<style scoped lang="scss">
.drop-area {
    text-align: center;
    font: 20pt bold arial;
}

.dot-line {
    border: 2px dashed rgba(127, 127, 127, 0.4);
    -webkit-border-radius: 4px;
    border-radius: 4px;
}

.drop-area:hover {
    background: rgba(127, 127, 127, 0.2);
    filter: brightness(90%);
}

.upload-icon {
    animation: vertical 0.4s ease-in-out infinite alternate;
}
@keyframes vertical {
    0% {
        transform: translateY(-20px);
    }
    100% {
        transform: translateY(0px);
    }
}
</style>

/**
 * @packageDocumentation
 * @module Core
 * @preferred
 */

/**
 * @summary 文字列を16進数相互変換する処理を提供します．
 */
export namespace StringToHexEncoderUtility {
    /**
     * ニックネームからPeer ID を生成
     * @param  name ニックネーム
     * @returns Peer ID
     */
    // eslint-disable-next-line no-inner-declarations
    export function generatePeerId(name: string): string {
        const id = btoa(unescape(encodeURIComponent(name)));
        console.log("encoded peer id " + name + " to " + id);
        // 名前かぶりを防ぐため識別子を追加
        return `${id.split("+").join("-").split("=").join("_").split("/").join(" ")}${getRamdomString(2)}`;
    }

    /**
     * Peer ID を名前にデコード
     * @param  peerId
     * @returns 名前
     */
    // eslint-disable-next-line no-inner-declarations
    export function decodePeerId(peerId: string): string {
        // 識別子2文字を消す
        const split = peerId.slice(0, -2).split("-").join("+").split("_").join("=").split(" ").join("/");
        console.log("decode id : " + split);
        const decode = decodeURIComponent(escape(atob(split)));
        return decode;
    }

    /**
     * ランダムな文字を生成
     * @param  length 文字の長さ
     * @returns  ランダムな文字列
     */
    // eslint-disable-next-line no-inner-declarations
    export function getRamdomString(length: number): string {
        // 生成する文字列に含める文字セット
        const c = "abcdefghijklmnopqrstuvwxyz0123456789";
        const cl = c.length;
        let r = "";
        for (let i = 0; i < length; i++) {
            r += c[Math.floor(Math.random() * cl)];
        }
        return r;
    }
}

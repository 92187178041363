import axios from "axios";
import { container } from "tsyringe";
import { AuthService } from "@/models/auth/AuthService";
import { config } from "@/config";
import { Service } from "axios-middleware";

const service = new Service(axios);
service.register({
    async onRequest(request) {
        // ゲストじゃなかったら
        if (!location.pathname.includes("extern")) {
            const authService = container.resolve(AuthService);
            // 期限が切れていたら自動リフレッシュ. /authへのリクエストの場合はチェックしない．
            if (authService.refreshToken !== "" && !authService.getIsAuthenticated() && !request.url.includes("auth")) {
                const isSuccess = await authService.tryRefreshToken();
                console.log("refresh in axios middleware", request.url, isSuccess, authService.getIsAuthenticated());
                if (!isSuccess) {
                    console.error("リフレッシュに失敗しました．");
                    return;
                }
            }

            if (authService.token !== "") {
                request.headers.Authorization = `Bearer ${authService.token}`;
                request.headers["X-Zentalk-GroupId"] = authService.currentGroupId;
            }
        }

        if (!request.url.startsWith("http") && !request.url.startsWith("//")) {
            request.url = config.url + request.url;
        }
        return request;
    }
});

export default axios;

import { LayerBase } from "./LayerBase";
import Konva from "konva";
import { v4 } from "uuid";
export class ArrowLayer extends LayerBase {
    public update(x: number, y: number) {
        super.update(x, y);

        if (!LayerBase.isDrawing || !LayerBase.currentShape) return;
        (LayerBase.currentShape as Konva.Arrow).points()[2] = x;
        (LayerBase.currentShape as Konva.Arrow).points()[3] = y;
        this.layer.draw();
    }

    protected createShape(x: number, y: number) {
        return new Konva.Arrow({
            id: v4(),
            points: [x, y],
            stroke: this.color,
            strokeWidth: this.strokeWidth,
            draggable: true
        });
    }
}

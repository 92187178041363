import Vue from "vue";
import VueRouter from "vue-router";
import SettingPage from "@/components/Managements/Setting/SettingPage.vue";
import { async } from "rxjs";
import { toColoredPartMask } from "@tensorflow-models/body-pix";

Vue.use(VueRouter);

export const routes = [
    {
        path: "/",
        name: "top",
        component: () => import("@/components/Managements/Rooms/RoomConnectionPage.vue"),
        icon: "mdi-account-supervisor",
        title: "接続"
    },
    {
        path: "/profile",
        name: "profile",
        component:()=>import(/* webpackChunkName: 'ProfileSettingPage' */ "@/components/Managements/Profile/ProfileSettingPage.vue"),
        icon: "mdi-account-box",
        title: "プロフィール"
    },
    {
        path: "/document",
        name: "document",
        component: () => import(/* webpackChunkName: 'DocumentSettingPage' */ "@/components/Managements/Document/DocumentSettingPage.vue"),
        icon: "mdi-text-box-multiple-outline",
        title: "資料",
        children: [
            {
                path: "/document/:dgId/edit/:id",
                component: () => import(/* webpackChunkName: 'DocumentEditPage' */ "@/components/Managements/Document/DocumentEditPage.vue"),
            },
            {
                path: "/document/:id",
                component: () => import(/* webpackChunkName: 'DocumentListView' */ "@/components/Managements/Document/molecles/DocumentListView.vue"),
            }
        ]
    },
    {
        path: "/history",
        name: "history",
        component: () => import(/* webpackChunkName: 'HistoryPage' */ "@/components/Managements/History/HistoryPage.vue"),
        icon: "mdi-history",
        title: "接続履歴"
    },
    {
        path: "/settings",
        name: "settings",
        component: SettingPage,
        icon: "mdi-cog-outline",
        title: "設定"
    },
];

const connectionRoutes = [
    {
        path: "/",
        component: () => import("@/components/Managements/ManagementsMain.vue"),
        children: routes,
        icon: "mdi-history",
        title: "管理画面"
    },
    {
        path: "/connection-video-chat",
        name: "connection-video-chat",
        component: () => import("@/components/Connections/main/VideoChatModeMain.vue"),
    },
    {
        path: "/connection-document-share",
        name: "connection-document-share",
        component: () => import("@/components/Connections/main/DocumentShareModeMain.vue"),
    },
    {
        path: "/connection-video-chat-mobile",
        name: "connection-video-chat-mobile",
        component: () => import("@/components/Connections/main/VideoChatModeMainMobile.vue"),
    },
    {
        path: "/connection-document-share-mobile",
        name: "connection-document-share-mobile",
        component: () => import("@/components/Connections/main/DocumentShareModeMainMobile.vue"),
    },
    {
        path: "/extern-video-chat",
        name: "extern-video-chat",
        component: () => import("@/components/Connections/extern/ExternVideoChatModeMain.vue"),
    },
    {
        path: "/extern-document-share",
        name: "extern-document-share",
        component: () => import("@/components/Connections/extern/ExternDocumentShareModeMain.vue"),
    },
    {
        path: "/extern-video-chat-mobile",
        name: "extern-video-chat-mobile",
        component: () => import("@/components/Connections/extern/ExternVideoChatModeMainMobile.vue"),
    },
    {
        path: "/extern-connection-setting",
        name: "extern-connection-setting",
        component: () => import("@/components/Connections/organisms/EnterConenctionSettingDialog.vue"),
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes: connectionRoutes

});

export default router;

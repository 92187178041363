











































































































import { Vue, Component } from "vue-property-decorator";
import { container } from "tsyringe";
import { AuthService } from "@/models/auth/AuthService";
import { config } from "@/config";
import Axios from "axios";
import { SuccessResponse } from "../../../models/api/Response";

/**
 * アプリケーションの設定画面を提供します.
 */
@Component
export default class SettingPage extends Vue {
    private readonly authService = container.resolve(AuthService);

    private async openAccountManagements() {
        const response = await Axios.get<SuccessResponse<{ edit: string; }>>(`/api/members/edit_url?memberId=${this.authService.memberId}`);
        location.assign(response.data.data.edit);
        this.authService.fetchMemberInfo();
    }

    private async edit(member: { id: number; name: string }) {
        const response = await Axios.get<SuccessResponse<{ edit: string; }>>(`/api/members/edit_url?memberId=${member.id}`);
        location.assign(response.data.data.edit);
        this.authService.fetchMemberInfo();
    }

    private async add(member: { id: number; name: string }) {
        const response = await Axios.get<SuccessResponse<{ add: string; }>>(`/api/members/add_url?memberId=${this.authService.memberId}`);
        location.assign(response.data.data.add);
        this.authService.fetchMemberInfo();
    }

    private get isUserAdmin() {
        const [own] = this.authService.membersList.filter(x => x.id === this.authService.memberId);
        return own ? own.isUserAdmin : false;
    }

    // region getters
    /**
     * ダークテーマかどうか
     * @description データはローカルストレージに保存しています. プロパティにアクセスした際にvuetifyのテーマに適用します.
     */
    private get isDark(): boolean {
        const isDark = localStorage.getItem("isDark") === "true";
        this.$vuetify.theme.dark = isDark;
        return isDark;
    }
    private set isDark(isDark: boolean) {
        this.$vuetify.theme.dark = isDark;
        localStorage.setItem("isDark", isDark ? "true" : "false");
    }
    // #endregion
}

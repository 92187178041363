import axios from "axios";
import { IRoomInfo } from "@/models/room/IRoomInfo";
import { RoomInfo } from "@/models/room/RoomInfo";
import { SuccessResponse } from "@/models/api/Response";
import { inject, singleton } from "tsyringe";
import { WebSocketService } from "@/models/web-socket/WebSocketService";
import { AuthService } from "@/models/auth/AuthService";
import { delay } from "@/models/utilities/timer";
import { WebSocketMessage } from "@/models/web-socket/WebSocketMessage";
import { HistoryService } from "@/models/histories/HistoryService";
import { DateTime } from "luxon";
import Axios from "axios";
import { IHistory } from "../histories/IHistory";

/**
 * ルーム情報に関するサービスを提供します.
 */
@singleton()
export class RoomsManagementService {
    // #regoin private fields
    private _rooms: { [key: number]: (IRoomInfo & { history: IHistory }) | null; } = {};
    private _roomSlotCount = 32;
    // #endregion

    // #region public gettters
    /**
     * ルーム情報一覧
     */
    public get rooms(): { [key: number]: (IRoomInfo & { history: IHistory }) | null; } {
        return this._rooms;
    }

    /**
     * ルームスロット数（契約ルーム数）
     */
    public get roomSlotCount(): number {
        return this._roomSlotCount;
    }
    // #endregion

    // #region methods
    /**
     * ルーム情報一覧をセットします。
     * @param rooms セットするルーム情報一覧
     */
    public setRooms(rooms: { [key: number]: (IRoomInfo & { history: IHistory }) }) {
        for (let i = 0; i < this.roomSlotCount; i++) {
            this._rooms[i] = null;
        }
        for (const key in rooms) {
            this._rooms[key] = rooms[key];
        }
    }

    /**
     * コンストラクタ
     * @param authService 認証サービス
     * @param webSocketService WebSocketサービス
     */
    public constructor(
        @inject(AuthService) private readonly authService: AuthService,
        @inject(WebSocketService) private readonly webSocketService: WebSocketService<WebSocketMessage<IRoomInfo>>,
        @inject(HistoryService) private readonly historyService: HistoryService,
    ) {
        this.setRooms({});
    }

    /**
     * WebSocketサーバーへ接続してメッセージを監視します．
     * @param groupId グループID
     * @param token アクセストークン
     */
    public async serve(groupId?: number, token?: string): Promise<void> {
        try {
            this.webSocketService.connect(groupId, token).then(async () => {
                this.webSocketService.onRecieved.add(x => {
                    if (x.type === "rooms") {
                        this.fetchRooms();
                    }
                });
            });
        }
        catch (ex) {
            console.error("failed websocket in connection service", ex);
        }
    }

    public async sendHistory(roomId: number, message: string, detail: string): Promise<boolean> {
        try {
            const historyResponse = await axios.get<SuccessResponse<{ count: number; histories: { chId: string }[]; }>>("/api/connection-histories?roomId=" + roomId);
            const response = await axios.post<SuccessResponse<IRoomInfo>>(`/api/connection-histories/${historyResponse.data.data.histories[0].chId}/details`, {
                comment: message,
                detail,
                createdAt: new Date(),
                rating: "0"
            });
            return true;
        }
        catch (ex) {
            logger.error("接続履歴の作成に失敗しました。", ex);
            console.error("接続履歴の作成に失敗しました。", ex);
        }
        return false;
    }

    /**
     * ルーム情報一覧をサーバーから取り込みます.
     * @return 成功したかどうか
     */
    public async fetchRooms(): Promise<boolean> {
        try {
            const response = await axios.get<SuccessResponse<(IRoomInfo & { history: IHistory })[]>>("/api/rooms");
            const data = response.data.data;
            this.setRooms(data);
            return true;
        }
        catch (ex) {
            logger.error(["ルーム情報一覧の取り込みに失敗しました。", ex]);
        }
        return false;
    }

    /**
     * ルームを作成します.
     * @param roomInfo 作成するルーム情報
     */
    public async createRoom(roomInfo: IRoomInfo): Promise<(IRoomInfo & { history: IHistory }) | undefined> {
        try {
            const response = await axios.post<SuccessResponse<any>>("/api/rooms", roomInfo);
            const createdRoom = (await axios.get<SuccessResponse<(IRoomInfo & { history: IHistory })>>("/api/rooms/" + response.data.data.created)).data.data;
            this.webSocketService.send("broadcastToGroup", new WebSocketMessage("rooms", createdRoom));
            this.rooms[createdRoom.slot] = createdRoom;
            return createdRoom;
        }
        catch (ex) {
            logger.error("ルームの作成に失敗しました。", ex);
        }
        return undefined;
    }

    public async createHistory(roomId: number): Promise<IHistory | undefined> {
        try {
            const response = await axios.post<IHistory>("/api/connection-histories", { roomId, startedAt: new Date(), endedAt: new Date() });
            return response.data;
        }
        catch (ex) {
            logger.error(["接続履歴の作成に失敗しました", ex]);
        }
        return undefined;
    }

    public async create(historyId: string, startedAt: Date): Promise<boolean> {
        try {
            const response = await axios.put<SuccessResponse<any>>("/api/histories/" + historyId, { startedAt });
            return true;
        }
        catch (ex) {
            logger.error("ルームの更新に失敗しました。", ex);
        }
        return false;
    }

    /**
     * ルーム情報を更新します.
     * @param roomInfo 作成するルーム情報
     */
    public async updateRoom(roomInfo: IRoomInfo): Promise<IRoomInfo | undefined> {
        try {
            (roomInfo as any).currentScreen = undefined;
            roomInfo.currentDocPage = undefined;
            const room = { ...roomInfo } as any;
            room.slot = undefined;
            const response = await axios.put<SuccessResponse<any>>("/api/rooms/" + roomInfo.roomId, room);
            const createdRoom = response.data.data;
            this.webSocketService.send("broadcastToGroup", new WebSocketMessage("rooms", createdRoom));
            this.rooms[createdRoom.slot] = response.data.data;
            return response.data.data;
        }
        catch (ex) {
            logger.error("ルームの更新に失敗しました。", ex);
        }
        return undefined;
    }

    /**
     * ルームを削除します.
     * @param roomId 削除するルームID
     */
    public async deleteRoom(room: IRoomInfo): Promise<boolean> {
        try {
            if (room.status === "connecting") {
                this.sendHistory(room.roomId, "ルームを強制終了しました", "システム");
            }

            await axios.delete("/api/rooms/" + room.roomId);
            this.webSocketService.send("broadcastToGroup", new WebSocketMessage("rooms", room));
            this.webSocketService.send("broadcastToGroup", new WebSocketMessage("shut", room));
            this.rooms[room.slot] = null;
            return true;
        }
        catch (ex) {
            logger.error("ルームの削除に失敗しました。", ex);
        }
        return false;
    }
    // #endregion
}

import Axios from "axios";
import { SuccessResponse } from "../api/Response";
import { MultiCastDelegate } from "ui-gallery";
import { IDocument } from "./IDocument";
import { Document } from "./Document";
import { from } from "linq";
import { RoomInfoService } from "@/models/room/RoomInfoService";
import { WebSocketService } from "@/models/web-socket/WebSocketService";
import { WebSocketMessage } from "../web-socket/WebSocketMessage";
import { IDocumentPage } from "./IDocumentPage";
import { DocumentPage } from "./DocumentPage";
import { container, inject } from "tsyringe";
import { v4 } from "uuid";

const id = v4();

export class DocumentShareService {
    /**
     * 資料一覧
     */
    public _document: Document<IDocumentPage> | null = null;

    /**
     * 接続終了メッセージを受け取ったときに実行されるイベントハンドラ
     */
    public readonly documentRecieved = new MultiCastDelegate<(doc: Document<IDocumentPage>) => void>();
    public readonly videoPlayRecieved = new MultiCastDelegate<() => void>();
    public readonly videoStopRecieved = new MultiCastDelegate<() => void>();
    public readonly seekRecieved = new MultiCastDelegate<(position: number) => void>();

    public _isLoading = false;

    public get isLoading() {
        return this._isLoading;
    }

    /**
     * ページが変更されたれた時のイベントハンドラ
     */
    public readonly pageChangeRecieved = new MultiCastDelegate<(pageInfo: { page: number }) => void>();

    private readonly roomInfoService = container.resolve(RoomInfoService);

    public _selected = 1;

    public get document() {
        return this._document;
    }

    /**
     * 選択中のページのインデックス
     */
    public get selectedIndex() {
        return this._selected - 1;
    }
    public set selectedIndex(value: number) {
        this._selected = value + 1;
    }

    public get selectedPage(): DocumentPage | null {
        if (this.document && this.document.pages.length > this.selectedIndex) {
            return this.document.pages[this.selectedIndex];
        }
        return null;
    }

    /**
     * 選択中のページ番号
     */
    public get selected(): number {
        return this._selected;
    }
    public set selected(value: number) {
        this._selected = value;
        this.sendMessageToRoom("documentPage", { page: value });
        this.savePage(value);
    }

    private savePage(page: number) {
        try {
            const room = this.roomInfoService.roomInfo;
            if (!room) {
                throw new Error("room not found in seve screen mode.");
            }
            room.currentDocPage = page;
            this.roomInfoService.updateRoom(room);
        }
        catch (ex) {
            console.error(ex);
        }
    }

    /**
     * コンストラクタ
     */
    public constructor(@inject(WebSocketService)readonly webSocketService: WebSocketService<WebSocketMessage<any>>) {
    }
    /**
     * ドキュメントをルームに共有します．
     * @param docId ドキュメントID
     */
    public async shareToRoom(docId: string): Promise<boolean> {
        try {
            const result = await Axios.post<SuccessResponse<Document<IDocumentPage>>>(`/api/docs/${docId}/duplicate`);
            this.selectedIndex = 0;
            this._document = new Document(result.data.data);
            this.sendMessageToRoom("document", this.document);
            return true;
        }
        catch (ex) {
            console.error(ex);
            logger.error(ex);
        }
        return false;
    }

    public videoSeek(position: number) {
        this.sendMessageToRoom("seek", position);
    }

    public videoPlay() {
        this.sendMessageToRoom("play", {});
    }

    public stopVideo() {
        this.sendMessageToRoom("stop", {});
    }

    public async fetchDocument(docId: string): Promise<boolean> {
        try {
            this._isLoading = true;
            const result = await Axios.get<SuccessResponse<Document<IDocumentPage>>>("/api/docs/" + docId);
            if (this.roomInfoService.roomInfo) {
                this.selected = this.roomInfoService.roomInfo.currentDocPage || 0;
            }
            this._document = new Document(result.data.data);
            return true;
        }
        catch (ex) {
            console.error(ex);
            logger.error(ex);
        }
        finally {
            this._isLoading = false;
        }
        return false;
    }

    /**
     * WebSocketサーバーへ接続してメッセージを監視します．
     * @param roomId ルームID
     * @param groupId グループID
     * @param token アクセストークン
     */
    public async serve(roomId: number, groupId?: number, token?: string): Promise<void> {
        try {
            this.webSocketService.onRecieved.add(e => {
                if (e.clientHash === id) return;

                if (e.type === "seek") {
                    this.seekRecieved.invoke(e.data);
                }
                else if (e.type === "play") {
                    this.videoPlayRecieved.invoke(undefined);
                }
                else if (e.type === "stop") {
                    this.videoStopRecieved.invoke(undefined);
                }
                else if (e.type === "documentPage") {
                    this._selected = e.data.page;
                    this.pageChangeRecieved.invoke(e.data.page);
                }
                else if (e.type === "document") {
                    this._document = e.data as Document<IDocumentPage>;
                    this._selected = 1;
                    this.documentRecieved.invoke(e.data);
                }
            });
        }
        catch (ex) {
            console.error("------", ex);
        }
    }

    /**
     * WebSocketでルーム全員にメッセージを送信します．
     * @param mode メッセージの種類
     * @param data 送信するデータ
     */
    private sendMessageToRoom(mode: string, data: any) {
        this.webSocketService.send("broadcastToRoom", new WebSocketMessage(mode, data, id));
    }
}

export class ShapeData {
    shape: "removeAll" | "line" | "text" | "rect" | "arrow" = "line";
    id = "";
    text = "";
    points: number[] = [];
    stroke = "";
    strokeWidth = 0;
    fontSize = 0;
    fill = "#3f3a8d";
    fontFamily = "Yu Gothic";
    x = 0;
    y = 0;
    width = 0;
    height = 0;
    scaleX = 1;
    scaleY = 1;
    rotation = 0;

    constructor(data: Partial<ShapeData>) {
        Object.assign(this, data);
    }
}

import Axios from "axios";
import { Profile } from "@/models/profile/Profile";
import { SuccessResponse } from "@/models/api/Response";
import { AuthService } from "@/models/auth/AuthService";
import { WebSocketService } from "@/models/web-socket/WebSocketService";
import { container } from "tsyringe";
import { MultiCastDelegate } from "ui-gallery";
import { WebSocketMessage } from "@/models/web-socket/WebSocketMessage";
import { config } from "@/config";

const clientHash = Math.floor(Math.random() * 100000000).toString();

/**
 * ルーム情報に関するサービスを提供します.
 */
export class ProfileService {
    // #regoin private fields
    private _profiles: Profile[] = [];
    private _profileHistories: Profile[] = [];
    private authService = container.resolve(AuthService);
    private webSocketService = container.resolve(WebSocketService) as WebSocketService<WebSocketMessage<Profile>>;
    public _recieveProfileHandler = new MultiCastDelegate<(profile: Profile) => void>();
    // #endregion

    // #region public gettters
    /**
     * プロフィールを受信した際のイベントハンドラ
     */
    public get recieveProfileHandler(): MultiCastDelegate<(profile: Profile) => void> {
        return this._recieveProfileHandler;
    }

    /**
     * ルーム情報一覧
     */
    public get profiles(): Profile[] {
        return this._profiles;
    }

    /*
     * ルーム情報一覧
     */
    public get profileHistories(): Profile[] {
        return this._profileHistories;
    }
    // #endregion

    // public mutations
    /**
     * ルーム情報一覧をセットします。
     * @param roomInfo セットするルーム情報一覧
     */
    public setProfiles(profiles: Profile[]) {
        this._profiles = profiles;
    }
    // #endregion

    /**
     * ルーム情報をサーバーから取り込みます.
     * @param key ルームの鍵
     * @return 成功したかどうか
     */
    public async fetchProfilesAsync(): Promise<boolean> {
        try {
            const response = await Axios.get<SuccessResponse<Profile[]>>(config.camelUrl + "/api/profiles", {
                params: {
                    groupId: this.authService.currentGroupId,
                    memberId: this.authService.memberId
                }
            });
            if (response.data.status) {
                this.setProfiles([...response.data.data]);
            }
            return true;
        }
        catch (ex) {
            console.error(ex);
            logger.error(ex);
        }
        return false;
    }

    /**
     * プロフィールをルームのメンバー全員に送信します．
     * @param profile プロフィール
     */
    public async send(profile: Profile): Promise<void> {
        this.webSocketService.send("broadcastToRoom", new WebSocketMessage("profile", profile, clientHash));
    }

    /**
     * WebSocketへ接続して画面の切り替えを監視します．
     * @param roomId ルームID
     * @param groupId グループID
     * @param token アクセストークン
     */
    public async serve(roomId: number, groupId?: number, token?: string): Promise<void> {
        try {
            this.webSocketService.onRecieved.add(e => {
                if (e.type === "profile" && e.clientHash !== clientHash) {
                    this.recieveProfileHandler.invoke(e.data);
                    this.profileHistories.push(e.data);
                }
            });
        }
        catch (ex) {
            console.error(ex);
        }
    }
}

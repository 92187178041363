<template>
    <v-dialog
        persistent
        v-model="isShow"
        max-width="580"
    >
        <v-card v-if="context.text">
            <v-card-text
                class="headline pt-5"
                v-html="context.text"
            ></v-card-text>
            <v-text-field
                v-model="text"
                filled
                hide-details
                class="mx-5 my-4"
            ></v-text-field>
            <v-card-actions>
                <div class="flex-grow-1"></div>
                <v-btn
                    text
                    color="black"
                    @click="close(undefined)"
                >
                    <v-icon>mdi-close</v-icon>
                    キャンセル
                </v-btn>
                <v-btn
                    color="primary"
                    @click="close(text)"
                    text
                >
                    <v-icon>mdi-check</v-icon>Ok
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script lang="ts">
/**
 * @packageDocumentation
 * @module Components
 * @preferred
 */
import { Vue, Prop, Component, Mixins, Watch } from "vue-property-decorator";
import DialogBase from "./DialogBase.vue";
import { ConfirmDialogContent } from "./ConfirmDialogContent";
import { VCard, VDialog, VCardTitle, VCardText, VCardActions, VBtn, VIcon, VTextField } from "vuetify/lib";

interface IDialog<T, TResult> {
    showAsync(param: T): Promise<TResult>;
}

/**
 * @summary 入力ダイアログを提供します.
 */
@Component({ components: { VCard, VDialog, VCardTitle, VCardText, VCardActions, VBtn, VIcon, VTextField } })
export default class InputDialog extends Mixins(DialogBase) {
    @Watch("isShow")
    changed() {
        this.text = (this as any).context.initialValue || "";
    }

    private text = "";
}
</script>

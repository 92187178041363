import Axios from "axios";
import { SuccessResponse } from "../api/Response";
import { ISetting } from "./ISetting";
import { Setting } from "./Setting";

export class SettingService {
    /**
     * 設定情報
     */
    public setting: ISetting | null = new Setting();

    public activateMember(memberId: number) {
        try {
            Axios.post("/api/");
        }
        catch (ex) {
            logger.error(["メンバーの追加に失敗しました", ex]);
        }
    }

    /**
     * 設置情報を取り込みます．
     */
    public async fetchSettings(): Promise<void> {
        try {
            const result = await Axios.get<SuccessResponse<ISetting>>("/api/settings");
            const setting = new Setting(result.data.data);
            this.setting = setting;
        }
        catch (ex) {
            logger.error(["設定の取り込みに失敗しました", ex]);
            console.error("設定の取り込みに失敗しました．");
        }
    }
}
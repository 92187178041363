import { LayerBase } from "./LayerBase";
import Konva from "konva";
import { v4 } from "uuid";
export class RectLayer extends LayerBase {
    public update(x: number, y: number) {
        super.update(x, y);

        if (!LayerBase.isDrawing || !LayerBase.currentShape) return;
        const shape = (LayerBase.currentShape as Konva.Rect);
        shape.width(x - shape.x());
        shape.height(y - shape.y());
        this.layer.draw();
    }

    protected createShape(x: number, y: number) {
        return new Konva.Rect({
            id: v4(),
            x,
            y,
            stroke: this.color,
            strokeWidth: this.strokeWidth,
            draggable: true
        });
    }
}

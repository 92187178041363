import { MultiCastDelegate } from "ui-gallery";
import { singleton, inject } from "tsyringe";
import { AuthService } from "@/models/auth/AuthService";
import { delay } from "@/models/utilities/timer";
import { config } from "../../config";

/**
 * ルーム情報に関するサービスを提供します.
 */
@singleton()
export class WebSocketService<T> {
    // #region fields
    public readonly _onRecieved: MultiCastDelegate<(data: any) => void> = new MultiCastDelegate();
    private webSocket?: WebSocket;
    private isFirstRecieved = false;
    private retryCount = 0;
    private roomId?: number;
    // //#endregion

    // #region methods

    public get onRecieved(): MultiCastDelegate<(data: T) => void> {
        return this._onRecieved as MultiCastDelegate<(data: T) => void>;
    }

    /**
     * コンストラクタ
     */
    public constructor(@inject(AuthService) private readonly authService: AuthService) {
    }

    /**
     * WebSocketサーバーへ接続します．
     * @param groupId グループID
     * @param token トークン
     */
    public async connect(groupId?: number, token?: string): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            const webSocket = new WebSocket(`${config.webSocketUrl}?${token ? "token=" + token : ""}&${groupId ? "gid=" + groupId : ""}`);
            this.webSocket = webSocket;
            webSocket.onmessage = e => {
                if (!this.isFirstRecieved) {
                    this.isFirstRecieved = true;
                    resolve(true);
                }
                try {
                    if (e.data) {
                        const data = JSON.parse(e.data).data;
                        this.onRecieved.invoke(data ?? {});
                    }
                }
                catch (ex) {
                    console.error("WebSocketのデータの受信もしくはパースにに失敗しました．", ex);
                }
            };
            webSocket.onerror = e => {
                console.error(e);
                this.isFirstRecieved = false;
                this.reconnect(groupId, token);
            };
            webSocket.onopen = e => {
                console.log("WebSocketに接続しました．");
            };
            webSocket.onclose = e => {
                console.error("WebSocketサーバーから切断されました．", e);
                logger.error(["WebSocketサーバーから切断されました．", `token:${token}`, `groupId:${groupId}`, e]);
                this.isFirstRecieved = false;
                this.reconnect(groupId, token);
            };
        });
    }

    private async reconnect(groupId?: number, token?: string) {
        this.retryCount++;
        if (10 < this.retryCount) {
            setTimeout(() => {
                if (confirm("サーバーと切断されたためページを更新しますか？")) location.reload();
            }, 0);
            return;
        }
        await delay(5000);
        await this.authService.tryRefreshToken();
        console.log("WebSocketを再接続します");
        if (await this.connect(groupId, token) && this.roomId) {
            this.enter(this.roomId);
            console.log("WebSocketの再接続に成功しました．");
        }
    }

    /**
     * データを送信します．
     * @param event イベントの種類
     * @param data 送信するデータ
     */
    public send(event: "broadcastToGroup" | "broadcastToRoom", data: T) {
        const webSocket = this.webSocket;
        if (webSocket) {
            webSocket.send(JSON.stringify({
                event,
                data
            }));
        }
    }

    /**
     * ルームへ入室します．
     * @param roomId 入室するルームID
     */
    public enter(roomId: number) {
        this.roomId = roomId;
        const webSocket = this.webSocket;
        if (webSocket) {
            webSocket.send(JSON.stringify({
                event: "enter",
                data: { roomId }
            }));
        }
    }

    /**
     * ルームから退出します．
     */
    public leave() {
        const webSocket = this.webSocket;
        if (webSocket) {
            console.log("leave room");
            webSocket.send(JSON.stringify({
                event: "leave"
            }));
        }
    }
    // #endregion
}

import { IDisposeable, MultiCastDelegate } from "ui-gallery";
import Konva from "konva";
import { Shape } from "konva/types/Shape";
import { v4 } from "uuid";

/**
 * 図形を描画するレイヤーを実装するための基底クラスを提供します．
 */
export class LayerBase implements IDisposeable {
    protected static isDrawing = false;
    protected static isDragging = false;
    protected static currentShape?: Konva.Shape = undefined;
    protected layer: Konva.Layer;
    protected transformer: Konva.Transformer;
    protected stage: Konva.Stage;
    protected _color = "#808080";
    protected _strokeWidth = 10;

    // # region public properties
    public get color(): string {
        return this._color;
    }
    public set color(color: string) {
        if (LayerBase.currentShape) {
            LayerBase.currentShape.stroke(color);
            LayerBase.currentShape.draw();
        }
        this._color = color;
    }

    public get strokeWidth(): number {
        return this._strokeWidth;
    }
    public set strokeWidth(strokeWidth: number) {
        if (LayerBase.currentShape) {
            LayerBase.currentShape.strokeWidth(strokeWidth);
            LayerBase.currentShape.draw();
        }
        this._strokeWidth = strokeWidth;
    }

    public readonly drawHandler = new MultiCastDelegate<(shape: any) => void>();
    public readonly removedHandler = new MultiCastDelegate<(shape: any) => void>();
    public readonly movedHandler = new MultiCastDelegate<(shape: any) => void>();
    // #endregion

    public constructor(stage: Konva.Stage, layer: Konva.Layer, transformer: Konva.Transformer) {
        this.layer = layer;
        this.stage = stage;
        this.transformer = transformer;
    }

    public beginDraw(x: number, y: number) {
        if (LayerBase.isDrawing || LayerBase.isDragging) return;
        else if (LayerBase.currentShape) {
            LayerBase.currentShape = undefined;
            this.transformer.detach();
            this.layer.draw();
        }
        this.transformer.detach();
        LayerBase.isDrawing = true;
    }

    public update(x: number, y: number) {
        // 現在の図形が空であれば作成
        // Note: beginに書かないのはマウスが動かなければ図形を追加しないため
        if (!LayerBase.currentShape && LayerBase.isDrawing) {
            const shape = this.createShape(x, y);
            LayerBase.currentShape = shape;
            this.layer.add(shape);
        }
    }

    public onEndDrawing() {
        if (!LayerBase.isDrawing) return;
        LayerBase.isDrawing = false;

        const currentShape = LayerBase.currentShape;
        LayerBase.currentShape = undefined;
        if (!currentShape) return;

        this.addEvents(currentShape);

        this.drawHandler.invoke(currentShape);
    }

    private addEvents(currentShape: Shape) {
        currentShape.on("mouseover", e => {
            LayerBase.isDragging = true;
        });
        currentShape.on("mouseout", e => {
            LayerBase.isDragging = false;
        });
        currentShape.on("mouseup touchend", e => {
            this.movedHandler.invoke(e.target);
            LayerBase.isDragging = false;
        });
        currentShape.on("transformstart", e => {
            LayerBase.isDragging = true;
        });
        currentShape.on("transformend", e => {
            LayerBase.isDragging = false;
        });
        currentShape.on("mousedown touchstart", e => {
            LayerBase.currentShape = e.target as Konva.Shape;
            this.transformer.attachTo(e.target);
            LayerBase.isDragging = true;
            this.layer.draw();
        });
    }

    public add(shape: Shape) {
        this.addEvents(shape);
        this.layer.add(shape);
    }

    public find(id: string) {
        return this.layer.findOne(id);
    }

    public removeCurrentShape() {
        if (LayerBase.currentShape) {
            this.removedHandler.invoke(LayerBase.currentShape);
            LayerBase.currentShape.remove();
            LayerBase.currentShape = undefined;
            this.transformer.detach();
            this.layer.draw();
        }
    }

    public removeAllShape() {
        this.layer.removeChildren();
        LayerBase.currentShape = undefined;
        this.layer.add(this.transformer);
        this.transformer.detach();
        this.layer.draw();
    }

    protected createShape(x: number, y: number): Konva.Shape {
        return new Konva.Shape({
            id: v4(),
            points: [x, y],
            stroke: this.color,
            strokeWidth: this.strokeWidth,
            lineCap: "round",
            lineJoin: "round",
            draggable: true
        });
    }

    // #region helper methods
    // #endregoin

    // #region disposing supports
    private _isDisposed = false;
    /**
     * 破棄済みかどうか
     */
    public get isDisposed(): boolean {
        return this._isDisposed;
    }

    /**
     * リソースを廃棄します．
     */
    public dispose(): void {
        throw new Error("Method not implemented.");
    }
    // #endregion
}

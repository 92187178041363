/**
 * @packageDocumentation
 * @module Rtc
 * @preferred
 */

import { BrowserUtility, BrowserType } from "../core/utilities/BrowserUtility";
import { IDisposeable } from "../core/IDisposable";

declare var CanvasCaptureMediaStreamTrack: any;

/**
 * @summary 仮想のビデオStreamを提供します.
 */
export class VirtualVideoStreamGenerator {
    private static _stream: MediaStream | undefined = undefined;

    public static getVirtualVideoStream(): MediaStream {
        if (this._stream === undefined) {
            const canvasElement = document.createElement("canvas") as HTMLCanvasElement;
            const imageElement = document.getElementById("novideoImage") as HTMLImageElement;
            canvasElement.width = imageElement.naturalWidth;
            canvasElement.height = imageElement.naturalHeight;
            const context = canvasElement.getContext("2d");
            const canvasStream = (canvasElement as any).captureStream(30) as MediaStream;

            if (context) {
                setInterval(() => {
                    context.drawImage(imageElement, 0, 0, imageElement.naturalWidth, imageElement.naturalHeight);
                }, 1000);
            }
            this._stream = canvasStream;
            return canvasStream;
        }
        return this._stream!;
    }
}

/**
 * @summary WebRtcで使用するStreamのラッパーを提供します.
 */
export class RtcMediaStreamWrapper {
    // #region private fields
    // カメラが有効かどうか
    private _isVideoEnabled = false;
    // オーディオが有効かどうか
    private _isAudioEnabled = false;
    // オーディオエフェクトを適用したStream
    protected stream: MediaStream;
    // ソースStream
    protected srcStream: MediaStream;
    // ソースのStreamのID
    private _id = "";
    // オーディオコンテキスト
    protected _audioContexts: AudioContext[] = [];
    // #endregion

    // #region public propoerty
    /**
     * 適用しているMediaStream
     */
    public get mediaStream(): MediaStream {
        return this.stream;
    }

    /**
     * ID
     */
    public get id(): string {
        return this._id;
    }

    /**
     * カメラが有効かどうか
     */
    public get isVideoEnabled(): boolean {
        return this._isVideoEnabled;
    }

    public set isVideoEnabled(isEnable: boolean) {
        const tracks = this.stream.getVideoTracks();
        if (tracks !== undefined) {
            tracks.forEach(track => (track.enabled = isEnable));
        }
        this._isVideoEnabled = isEnable;
    }

    /**
     * オーディオが有効かどうか
     */
    public get isAudioEnabled(): boolean {
        return this._isAudioEnabled;
    }

    public set isAudioEnabled(isEnable: boolean) {
        const tracks = this.stream.getAudioTracks();
        if (tracks !== undefined) {
            tracks.forEach(track => (track.enabled = isEnable));
        }
        this._isAudioEnabled = isEnable;
    }
    // #endregion

    // #region public methods
    /**
     * コンストラクタ
     * @param stream 適用するstream
     * @param isGenerateVirtualAudioTrack オーディオトラックが存在しない場合に仮想のオーディオトラックを挿入するかどうか
     * @param isGenerateVirtualVideoTrack ビデオトラックが存在しない場合に仮想のビデオトラック（Novideo画像）を挿入するかどうか
     */
    public constructor(stream: MediaStream, isGenerateVirtualAudioTrack = false) {
        if (!stream) {
            throw Error("MediaStreamが無効です。");
        }
        const [aTrack] = stream.getAudioTracks();
        const [vTrack] = stream.getVideoTracks();
        this._isAudioEnabled = aTrack ? aTrack.enabled : false;
        this._isVideoEnabled = vTrack ? vTrack.enabled : false;

        this.srcStream = stream;
        this._id = stream.id;

        if (BrowserUtility.hasFlag(BrowserType.ios)) {
            this.stream = this.applyEffect(stream, isGenerateVirtualAudioTrack, false);
        }
        else {
            this.stream = this.applyEffect(stream, isGenerateVirtualAudioTrack, true);
        }
    }
    // #endregion

    // #region private methods
    /**
     * @summary StreamをWebRtcへ送信する前の前処理を適用します.
     * @param stream 適用するStream
     * @return 結果のStream
     */
    private applyEffect(
        stream: MediaStream,
        isGenerateVirtualAudioTrack: boolean,
        isApplyAudioEffect = true): MediaStream {
        const _AudioContext = (window as any).AudioContext || (window as any).webkitAudioContext;
        const audioContext = new _AudioContext() as AudioContext;
        this._audioContexts.push(audioContext);

        if (!stream.active) {
            const tracks: MediaStreamTrack[] = [];
            if (isGenerateVirtualAudioTrack) {
                // 仮想のオーディオトラックを作成
                const virtualAudioStream = audioContext.createMediaStreamDestination();
                const [aTrack] = virtualAudioStream.stream.getAudioTracks();
                tracks.push(aTrack);
            }
            return new MediaStream(tracks);
        }

        if (!this._isAudioEnabled) {
            // video:off audio:off
            if (!this._isVideoEnabled) {
                // 仮想のオーディオトラックを作成
                const tracks: MediaStreamTrack[] = [];
                if (isGenerateVirtualAudioTrack) {
                    const virtualAudioStream = audioContext.createMediaStreamDestination();
                    const [aTrack] = virtualAudioStream.stream.getAudioTracks();
                    tracks.push(aTrack);
                }

                return new MediaStream(tracks);
            }
            // video :on audio:off
            else {
                const [vTrack] = stream.getVideoTracks();
                const tracks: MediaStreamTrack[] = [];
                if (isGenerateVirtualAudioTrack) {
                    // 仮想のオーディオトラックを作成
                    const virtualAudioStream = audioContext.createMediaStreamDestination();
                    const [aTrack] = virtualAudioStream.stream.getAudioTracks();
                    tracks.push(aTrack);
                }
                tracks.push(vTrack);

                return new MediaStream(tracks);
            }
        }
        else {
            // video:off audio:on
            if (!this._isVideoEnabled) {
                const tracks: MediaStreamTrack[] = [];

                if (isApplyAudioEffect) {
                    const aTrack = this.applyAudioFilter(stream);
                    tracks.push(aTrack);
                }
                else {
                    const [aTrack] = stream.getAudioTracks();
                    tracks.push(aTrack);
                }

                return new MediaStream(tracks);
            }
            // video:on audio:on
            else {
                const tracks: MediaStreamTrack[] = [];
                if (isApplyAudioEffect) {
                    const aTrack = this.applyAudioFilter(stream);
                    tracks.push(aTrack);
                }
                else {
                    const [aTrack] = stream.getAudioTracks();
                    tracks.push(aTrack);
                }

                const [vTrack] = stream.getVideoTracks();
                tracks.push(vTrack);
                return new MediaStream(tracks);
            }
        }
    }

    /**
     * Streamにノイズキャンセルフィルタを適用させます．
     * @param stream 適用させるStream
     * @returns 結果のStream
     */
    private applyAudioFilter(stream: MediaStream): MediaStreamTrack {
        const _AudioContext = (window as any).AudioContext || (window as any).webkitAudioContext;
        const audioContext = new _AudioContext() as AudioContext;
        this._audioContexts.push(audioContext);

        const biquadFilter = audioContext.createBiquadFilter();
        biquadFilter.type = "lowpass";
        biquadFilter.frequency.value = 8192;

        const biquadFilter2 = audioContext.createBiquadFilter();
        biquadFilter2.type = "highpass";
        biquadFilter2.frequency.value = 50;

        const mediaStreamSource = audioContext.createMediaStreamSource(stream);
        const dstStreamNode = audioContext.createMediaStreamDestination();

        mediaStreamSource.connect(biquadFilter);
        biquadFilter.connect(biquadFilter2);
        biquadFilter2.connect(dstStreamNode);
        const [aTrack] = dstStreamNode.stream.getAudioTracks();
        return aTrack;
    }
    // #endregion
}

/**
 * @summary デバイスから取得したストリームを表すクラス
 */
export class DeviceMediaStreamWrapper extends RtcMediaStreamWrapper implements IDisposeable {
    // #region private fields
    /**
     * リソースが解放済みかどうか
     */
    private _isDisposed = false;
    // #endregion

    // #region public property
    /**
     * リソースが解放済みかどうか
     */
    public get isDisposed(): boolean {
        return this._isDisposed;
    }
    // #endregion

    // #region public methods
    /**
     * コンストラクタ
     * @param stream 適用するStream
     */
    public constructor(stream: MediaStream, isGeberateVirtualAudioTrack = false, readonly isBackCamera = false) {
        super(stream, isGeberateVirtualAudioTrack);
    }

    /**
     * リソースの破棄
     */
    public dispose(): void {
        if (!this._isDisposed) {
            if (this.stream) {
                this.stream.getTracks().forEach(track => {
                    // キャンバスではなかったらトラックを停止
                    if (BrowserUtility.hasFlag(BrowserType.FireFox)) {
                        track.stop();
                    }
                    else {
                        if (!(track instanceof CanvasCaptureMediaStreamTrack)) {
                            track.stop();
                        }
                    }
                });
            }
            this.srcStream.getTracks().forEach(track => track.stop());
            for (const ctx of this._audioContexts) {
                if (ctx) {
                    ctx.close();
                }
            }
            this._isDisposed = true;
        }
    }
    // #endregion
}

/**
 * @summary デバイス情報
 */
export class DeviceInfo {
    public id = "";
    public name = "";
}

/**
 * @summary デバイス情報を格納するリスト
 */
export class DeviceList {
    audioDeviceList: DeviceInfo[] = [];
    videoDeviceList: DeviceInfo[] = [];
    outputDeviceList: DeviceInfo[] = [];
    isAllowed = false;
}

/**
 * @summary デバイスストリームを管理する静的クラス
 */
export class DeviceStreamManager {
    // デバイスストリームのリスト
    private static deviceStreamList: DeviceMediaStreamWrapper[] = [];

    /**
     * @summary 全てのデバイスを停止しリソースを破棄
     */
    public static disposeAll(): void {
        const list = this.deviceStreamList;
        for (const key in list) {
            list[key].dispose();
            delete list[key];
        }
        this.deviceStreamList.length = 0;
    }

    /**
     * @summary 指定したデバイスストリームのリソースを破棄し管理から外す
     * @param stream 削除するストリーム
     */
    public static disposeAt(streamWrapper: DeviceMediaStreamWrapper): void {
        if (!streamWrapper) {
            return;
        }

        const list = this.deviceStreamList;
        for (const key in list) {
            if (list[key].id === streamWrapper.id) {
                list[key].dispose();
                list.splice(Number(key), 1);
            }
        }
    }

    /**
     * @summary スクリーンシェア用のデバイスストリームを取得
     */
    public static async getScreenShareStream(): Promise<DeviceMediaStreamWrapper | undefined> {
        try {
            const stream: MediaStream = await (navigator.mediaDevices as any)
                .getDisplayMedia({
                    video: {
                        width: 1280,
                        height: 720,
                        frameRate: 16
                    }
                });
            if (stream) {
                const deviceStream = new DeviceMediaStreamWrapper(stream, false, false);
                this.deviceStreamList.push(deviceStream);
                return deviceStream;
            }
            return undefined;
        }
        catch (ex) {
            logger.error(ex, ex.message);
        }
        return undefined;
    }

    /**
     * @summary カメラのデバイスストリームを取得
     * @param width 横幅
     * @param height 高さ
     * @param isVideoEnabled カメラを利用するか
     * @param videoDeviceId カメラのデバイスId(フロントカメラを指定する場合は”user”, バックカメラを指定する場合は”environment”)
     * @param isAudioEnebled マイクを使用するか
     * @param audioDeviceId マイクのデバイスId
     * @example const stream = await DeviceStreamManager.getDeviceStream(...);
     */
    public static async getDeviceStream(
        isVideoEnabled = true,
        isAudioEnebled = true,
        videoDeviceId?: string,
        audioDeviceId?: string,
        width = 640,
        height = 480,
        frameRate = 30,
    ): Promise<DeviceMediaStreamWrapper | undefined> {
        const w = Number(width), h = Number(height);
        logger.log(`request media device audio:${isAudioEnebled} AudioDeviceid:${audioDeviceId} video:${isVideoEnabled} VideoDeviceId:${videoDeviceId} ${width}*${height} frame:${frameRate}`);
        const stream =
            await navigator.mediaDevices.getUserMedia({
                audio: isAudioEnebled ? {
                    deviceId: audioDeviceId ? { exact: audioDeviceId } : undefined,
                    audioGainControl: true,
                    echoCancellation: true,
                    noiseSuppression: true,
                    echoCancellationType: "system"
                } as any : false,
                video: isVideoEnabled ? {
                    deviceId: (() => {
                        if (!videoDeviceId || videoDeviceId === "environment" || videoDeviceId === "user") {
                            return undefined;
                        }
                        return { exact: videoDeviceId };
                    })(),
                    width: w,
                    height: h,
                    frameRate,
                    facingMode: (() => {
                        if (videoDeviceId === "user") {
                            return { exact: "user" };
                        }
                        else if (videoDeviceId === "environment") {
                            return { exact: "environment" };
                        }
                        return undefined;
                    })()
                } : false
            });
        if (stream) {
            const deviceStream = new DeviceMediaStreamWrapper(stream, true, (() => {
                if (videoDeviceId === "environment") return true;
                return false;
            })());
            this.deviceStreamList.push(deviceStream);
            return deviceStream;
        }
        return undefined;
    }

    /**
     * @summary デバイスリストを取得
     * @returns デバイスリスト
     */
    public static async getDeviceList(): Promise<DeviceList> {
        const deviceList = new DeviceList();
        const deviceInfos = await navigator.mediaDevices.enumerateDevices();

        let isNotAllowed = false;
        for (const item of deviceInfos) {
            if (item.label) {
                // どれか一つでも空だったら許可されていないものとする
                isNotAllowed = isNotAllowed || !item.label;
            }

            if (item.kind === "audioinput") {
                deviceList.audioDeviceList.push({
                    id: item.deviceId,
                    name: item.label || "許可されていません"
                });
            }
            else if (item.kind === "audiooutput") {
                deviceList.outputDeviceList.push({
                    id: item.deviceId,
                    name: item.label || "許可されていません"
                });
            }
            else if (item.kind === "videoinput") {
                deviceList.videoDeviceList.push(
                    {
                        id: item.deviceId,
                        name: item.label || "許可されていません"
                    }
                );
            }
        }
        if (!isNotAllowed && deviceList.audioDeviceList.length !== 0 && deviceList.videoDeviceList.length !== 0) {
            deviceList.isAllowed = true;
        }

        // モバイルの場合はバックカメラとフロントカメラの識別のためfacingModeで指定させるための情報を追加
        if (BrowserUtility.hasFlag(BrowserType.Mobile)) {
            const devices = [];
            if (deviceList.videoDeviceList.length) {
                devices.push({
                    name: isNotAllowed ? "許可されていません" : "既定のフロントカメラ",
                    id: "user"
                });
                devices.push({
                    name: isNotAllowed ? "許可されていません" : "既定のバックカメラ",
                    id: "environment"
                });
            }
            deviceList.videoDeviceList = [
                ...devices,
                ...deviceList.videoDeviceList
            ];
        }
        return deviceList;
    }
}

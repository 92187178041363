
<template>
    <v-dialog v-model="isShow" max-width="580" persistent>
        <v-card v-if="context">
            <v-card-title class="headline" v-html="context.title"></v-card-title>
            <v-card-text v-html="context.text"></v-card-text>
            <v-card-actions>
                <div class="flex-grow-1"></div>
                <v-btn v-if="!context.hideCancelButton" text color="black" @click="close(false)">
                    <v-icon>mdi-close</v-icon>
                    {{context.cancelText}}
                </v-btn>
                <v-btn color="primary" @click="close(true)" text>
                    <v-icon>mdi-check</v-icon>
                    {{context.okText}}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script lang="ts">
/**
 * @packageDocumentation
 * @module Components
 * @preferred
 */
import { Vue, Prop, Component, Mixins } from "vue-property-decorator";
import DialogBase from "./DialogBase.vue";
import { ConfirmDialogContent } from "./ConfirmDialogContent";
import { VCard, VDialog, VCardTitle, VCardText, VCardActions, VBtn, VIcon } from "vuetify/lib";

interface IDialog<T, TResult> {
    showAsync(param: T): Promise<TResult>;
}

/**
 * @summary 確認用ダイアログを提供します.
 */
@Component({ components: { VCard, VDialog, VCardTitle, VCardText, VCardActions, VBtn, VIcon } })
export default class ConfirmDialog extends Mixins(DialogBase) {
}
</script>

import { WebSocketService } from "@/models/web-socket/WebSocketService";
import { WebSocketMessage } from "../web-socket/WebSocketMessage";
import { ShapeData } from "./ShapeData";
import Konva from "konva";
import { LineLayer } from "@/models/drawing/LineLayer";
import { ArrowLayer } from "@/models/drawing/ArrowLayer";
import { RectLayer } from "@/models/drawing/RectLayer";
import { TextLayer } from "@/models/drawing/TextLayer";
import { DrawingModeType } from "@/models/drawing/DrawingModeType";
import { LayerBase } from "@/models/drawing/LayerBase";
import { NodeConfig, Node } from "konva/types/Node";
import { v4 } from "uuid";
import { DocumentShareService } from "../documents/DocumentShareService";
import Axios from "axios";
import { SuccessResponse } from "../api/Response";
import { Marker } from "./Marker";
import { Subject } from "rxjs";

const uid = v4();

interface Zoom {
    scale: number;
    offsetX: number;
    offsetY: number;
}

/**
 * 各シェイプごとにレイヤーを分けたのは完全に設計ミスです！！！！！
 * 書き換えましょう
 */
export class DrawingService {
    public _color = "#f44336";
    public _strokeWidth = 8;
    public fontSize = 32;
    public _currentZoomScale = 1;
    public _drawingModeType = DrawingModeType.Line;

    private layers: { [key: string]: LayerBase } = {};
    private layer!: Konva.Layer;
    private transformer?: Konva.Transformer;
    private canvas!: Konva.Stage;

    public recieveZoomHandler: Subject<Zoom> = new Subject<Zoom>();

    public zoomEnabledHandler: Subject<boolean> = new Subject<boolean>();

    private _isZoomEnabled = false;

    public get isZoomEnabled() {
        return this._isZoomEnabled;
    }

    get currentZoomScale() {
        return this._currentZoomScale;
    }

    public get color(): string {
        return this._color;
    }
    public set color(value: string) {
        this._color = value;
        for (const key in DrawingModeType) {
            if (this.layers[key]) {
                this.layers[key].color = value;
            }
        }
    }

    public get drawingModeType() {
        return this._drawingModeType;
    }
    public set drawingModeType(value: DrawingModeType) {
        this._drawingModeType = value;
        this.detach();
    }

    public get strokeWidth(): number {
        return this._strokeWidth;
    }
    public set strokeWidth(value: number) {
        this._strokeWidth = value;
        for (const key in DrawingModeType) {
            this.layers[key].strokeWidth = this.strokeWidth;
        }
    }

    constructor(
        readonly webSocketService: WebSocketService<WebSocketMessage<ShapeData>>,
        readonly documentService: DocumentShareService) {
        this.zoomEnabledHandler.subscribe(e => this._isZoomEnabled = e);
    }

    public init(width: number, height: number) {
        this.canvas = new Konva.Stage({
            container: "drawing-container",
            width,
            height
        });
        const layer = new Konva.Layer();
        this.layer = layer;
        this.canvas.add(layer);
        const transformer = new Konva.Transformer();
        this.transformer = transformer;
        layer.add(transformer);

        // Line
        const lineLayer = new LineLayer(this.canvas, layer, transformer);
        lineLayer.color = this.color;
        lineLayer.strokeWidth = this.strokeWidth;
        lineLayer.drawHandler.add(this.onLineRecieved.bind(this));
        lineLayer.movedHandler.add(this.onLineMoved.bind(this));
        lineLayer.removedHandler.add(this.onLineRemoved.bind(this));
        this.layers[DrawingModeType.Line] = lineLayer;

        // Arrow
        const arrowLayer = new ArrowLayer(this.canvas, layer, transformer);
        arrowLayer.color = this.color;
        arrowLayer.strokeWidth = this.strokeWidth;
        arrowLayer.drawHandler.add(this.onArrowRecieved.bind(this));
        arrowLayer.movedHandler.add(this.onArrowMoved.bind(this));
        arrowLayer.removedHandler.add(this.onArrowRemoved.bind(this));
        this.layers[DrawingModeType.Arrow] = arrowLayer;

        // Rect
        const rectLayer = new RectLayer(this.canvas, layer, transformer);
        rectLayer.color = this.color;
        rectLayer.strokeWidth = this.strokeWidth;
        rectLayer.drawHandler.add(this.onRectRecieved.bind(this));
        rectLayer.movedHandler.add(this.onRectMoved.bind(this));
        rectLayer.removedHandler.add(this.onRectRemoved.bind(this));
        this.layers[DrawingModeType.Rect] = rectLayer;

        // Text
        const textLayer = new TextLayer(this.canvas, layer, transformer);
        textLayer.color = this.color;
        textLayer.strokeWidth = this.strokeWidth;
        textLayer.drawHandler.add(this.onTextRecieved.bind(this));
        textLayer.movedHandler.add(this.onTextMoved.bind(this));
        textLayer.removedHandler.add(this.onTextRemoved.bind(this));
        this.layers[DrawingModeType.Text] = textLayer;
    }

    public beginDraw(x: number, y: number) {
        this.layers[this.drawingModeType]?.beginDraw(x, y);
    }

    public endDrawing() {
        this.layers[this.drawingModeType]?.onEndDrawing();
    }

    public drawMove(e: { x: number, y: number }, rect: DOMRect) {
        this.layers[this.drawingModeType]?.update(
            this.sturate(e.x - rect.x, 0, rect.width),
            this.sturate(e.y - rect.y, 0, rect.height)
        );
    }

    public detach() {
        this.transformer?.detach();
    }

    public removeCurrentShape() {
        this.transformer?.getNode().remove();
        this.transformer?.detach();
        this.layers[DrawingModeType.Line]?.removeCurrentShape();
        this.layer.draw();
    }

    public syncZoom(data: Zoom) {
        this.sendMessageToRoom("zoom", data);
        this._currentZoomScale = data.scale;
    }

    public async removeAllShape(docId: string, xdpId: string) {
        this.layers[DrawingModeType.Line]?.removeAllShape();
        this.sendMessageToRoom("removeAll", {});
        const response = await Axios.get<SuccessResponse<Marker[]>>(`/api/docs/${docId}/pages/${xdpId}/markers`);
        const makerData = response.data.data;
        for (const marker of makerData) {
            Axios.delete(`/api/docs/${docId}/pages/${xdpId}/markers/${marker.markerId}`);
        }
    }

    public async fetchCurrentPage(docId: string, xdpId: string): Promise<void> {
        this.layers[DrawingModeType.Line]?.removeAllShape();
        try {
            const response = await Axios.get<SuccessResponse<Marker[]>>(`/api/docs/${docId}/pages/${xdpId}/markers`);
            const makerData = response.data.data;

            for (const marker of makerData) {
                const shape = marker.content;
                if (shape.shape === "line") {
                    this.layers[DrawingModeType.Line].add(new Konva.Line({
                        points: this.coordinate(shape.points, this.canvas.width() / shape.width, this.canvas.height() / shape.height),
                        stroke: shape.stroke,
                        strokeWidth: shape.strokeWidth,
                        lineCap: "round",
                        lineJoin: "round",
                        draggable: true,
                        scaleX: shape.scaleX,
                        scaleY: shape.scaleY,
                        x: shape.x,
                        y: shape.y,
                        rotation: shape.rotation,
                        id: marker.markerId
                    }));
                }
                else if (shape.shape === "rect") {
                    const rect = this.coordinate(shape.points, this.canvas.width() / shape.width, this.canvas.height() / shape.height);
                    this.layers[DrawingModeType.Line].add(new Konva.Rect({
                        x: rect[0],
                        y: rect[1],
                        stroke: shape.stroke,
                        strokeWidth: shape.strokeWidth,
                        draggable: true,
                        scaleX: shape.scaleX,
                        scaleY: shape.scaleY,
                        rotation: shape.rotation,
                        width: rect[2],
                        height: rect[3],
                        id: marker.markerId
                    }));
                }
                else if (shape.shape === "arrow") {
                    this.layers[DrawingModeType.Line].add(new Konva.Arrow({
                        points: this.coordinate(shape.points, this.canvas.width() / shape.width, this.canvas.height() / shape.height),
                        stroke: shape.stroke,
                        strokeWidth: shape.strokeWidth,
                        draggable: true,
                        scaleX: shape.scaleX,
                        scaleY: shape.scaleY,
                        fillPatternOffsetX: shape.x,
                        y: shape.x,
                        rotation: shape.rotation,
                        id: marker.markerId
                    }));
                }
                else if (shape.shape === "text") {
                    const rect = this.coordinate(shape.points, this.canvas.width() / shape.width, this.canvas.height() / shape.height);
                    this.layers[DrawingModeType.Line].add(new Konva.Text({
                        x: rect[0],
                        y: rect[1],
                        stroke: shape.stroke,
                        strokeWidth: shape.strokeWidth,
                        draggable: true,
                        scaleX: shape.scaleX,
                        scaleY: shape.scaleY,
                        rotation: shape.rotation,
                        id: shape.id,
                        width: rect[2],
                        height: rect[3],
                        text: marker.markerId
                    }));
                }
            }
            this.canvas.draw();
        }
        catch (ex) {
            console.error("マーカーの取得に失敗しました");
            logger.error("マーカーの取得に失敗しました", ex);
        }
    }

    /**
     * すべてのノードの座標に指定したキャンバスの比率を適用します．
     */
    private applyRateToAllShapesPosition(
        nodes: Node[],
        widthRate: number,
        heightRate: number
    ) {
        for (const shape of nodes) {
            const points = shape.attrs.points as number[];

            const attrs = shape.attrs;
            if (!points) {
                if (!isNaN(attrs.x)) {
                    shape.position({
                        x: Math.round(attrs.x * widthRate),
                        y: Math.round(attrs.y * heightRate)
                    });
                    attrs.width = Math.round(attrs.width * widthRate);
                    attrs.height = Math.round(attrs.height * heightRate);
                }
                continue;
            }

            for (let i = 0; i < points.length; ++i) {
                if (i % 2 === 0) {
                    points[i] = Math.round(points[i] * widthRate);
                }
                else {
                    points[i] = Math.round(points[i] * heightRate);
                }
            }
            shape.attrs.points = points;
        }
    }

    public setIsZoomEnabled(value: boolean) {
        this._isZoomEnabled = value;
    }

    public syncIsZoomEnabled(value: boolean) {
        this.sendMessageToRoom("isZoomEnabled", value);
        this._isZoomEnabled = value;
    }

    public resize(width: number, height: number) {
        const canvasHeight = this.canvas.height();
        const canvasWidth = this.canvas.width();

        this.applyRateToAllShapesPosition(
            this.layer.getChildren().toArray(),
            width / canvasWidth,
            height / canvasHeight
        );

        this.canvas.width(width);
        this.canvas.height(height);
        this.canvas.show();
        this.canvas.draw();
    }

    private coordinate(points: number[], rateX: number, rateY: number): number[] {
        for (let i = 0; i < points.length; ++i) {
            if (i % 2 === 0) {
                points[i] = Math.round(points[i] * rateX);
            }
            else {
                points[i] = Math.round(points[i] * rateY);
            }
        }
        return points;
    }

    private async onRectRecieved(shape: Konva.Rect) {
        const data = new ShapeData({
            id: shape.id(),
            shape: "rect",
            points: [
                shape.position().x,
                shape.position().y,
                shape.width(),
                shape.height()
            ],
            stroke: shape.stroke(),
            strokeWidth: shape.strokeWidth(),
            width: this.canvas.width(),
            height: this.canvas.height(),
            scaleX: shape.scaleX(),
            scaleY: shape.scaleY(),
            rotation: shape.rotation()
        });
        try {
            if (!this.documentService.document || !this.documentService.selectedPage) throw new Error("展開されている資料がありません．");
            const resuponse = await Axios.post(`/api/docs/${this.documentService.document.docId}/pages/${this.documentService.selectedPage.xdpId}/markers`, { marker: data });
            data.id = resuponse.data.data.markerId;
            shape.id(resuponse.data.data.markerId);
            this.sendMessageToRoom("add", data);
        }
        catch (ex) {
            logger.error("マーカーの送信に失敗しました", ex);
            console.error("マーカーの送信に失敗しました", ex);
        }
    }

    private async onTextRecieved(shape: Konva.Text) {
        const data = new ShapeData({
            id: shape.id(),
            shape: "text",
            points: [
                shape.position().x,
                shape.position().y,
                shape.position().x + shape.width(),
                shape.position().y + shape.height()
            ],
            stroke: shape.stroke(),
            strokeWidth: shape.strokeWidth(),
            width: this.canvas.width(),
            height: this.canvas.height(),
            scaleX: shape.scaleX(),
            scaleY: shape.scaleY(),
            rotation: shape.rotation(),
            text: shape.text()
        });
        try {
            if (!this.documentService.document || !this.documentService.selectedPage) throw new Error("展開されている資料がありません．");
            const resuponse = await Axios.post(`/api/docs/${this.documentService.document.docId}/pages/${this.documentService.selectedPage.xdpId}/markers`, { marker: data });
            data.id = resuponse.data.data.markerId;
            shape.id(resuponse.data.data.markerId);
            this.sendMessageToRoom("add", data);
        }
        catch (ex) {
            logger.error("マーカーの送信に失敗しました", ex);
            console.error("マーカーの送信に失敗しました", ex);
        }
    }

    private async onLineRecieved(shape: Konva.Line) {
        const data = new ShapeData({
            id: shape.id(),
            shape: "line",
            points: [...shape.points()],
            stroke: shape.stroke(),
            strokeWidth: shape.strokeWidth(),
            width: this.canvas.width(),
            height: this.canvas.height(),
            scaleX: shape.scaleX(),
            scaleY: shape.scaleY(),
            x: shape.x(),
            y: shape.y(),
            rotation: shape.rotation()
        });
        try {
            if (!this.documentService.document || !this.documentService.selectedPage) throw new Error("展開されている資料がありません．");
            const resuponse = await Axios.post(`/api/docs/${this.documentService.document.docId}/pages/${this.documentService.selectedPage.xdpId}/markers`, { marker: data });
            data.id = resuponse.data.data.markerId;
            shape.id(resuponse.data.data.markerId);
            this.sendMessageToRoom("add", data);
        }
        catch (ex) {
            logger.error("マーカーの送信に失敗しました", ex);
            console.error("マーカーの送信に失敗しました", ex);
        }
    }

    private async onArrowRecieved(shape: Konva.Arrow) {
        const data = new ShapeData({
            id: shape.id(),
            shape: "arrow",
            points: [...shape.points()],
            stroke: shape.stroke(),
            strokeWidth: shape.strokeWidth(),
            width: this.canvas.width(),
            height: this.canvas.height(),
            scaleX: shape.scaleX(),
            scaleY: shape.scaleY(),
            x: shape.x(),
            y: shape.y(),
            rotation: shape.rotation()
        });
        try {
            if (!this.documentService.document || !this.documentService.selectedPage) throw new Error("展開されている資料がありません．");
            const resuponse = await Axios.post(`/api/docs/${this.documentService.document.docId}/pages/${this.documentService.selectedPage.xdpId}/markers`, { marker: data });
            data.id = resuponse.data.data.markerId;
            shape.id(resuponse.data.data.markerId);
            this.sendMessageToRoom("add", data);
        }
        catch (ex) {
            logger.error("マーカーの送信に失敗しました", ex);
            console.error("マーカーの送信に失敗しました", ex);
        }
    }

    private async onRectRemoved(shape: Konva.Rect) {
        const data = new ShapeData({
            id: shape.id(),
            shape: "rect"
        });
        this.sendMessageToRoom("remove", data);
        try {
            if (!this.documentService.document || !this.documentService.selectedPage) throw new Error("展開されている資料がありません．");
            const resuponse = await Axios.delete(`/api/docs/${this.documentService.document.docId}/pages/${this.documentService.selectedPage.xdpId}/markers/${shape.id()}`);
        }
        catch (ex) {
            logger.error("マーカーの送信に失敗しました", ex);
            console.error("マーカーの送信に失敗しました", ex);
        }
    }

    private async onTextRemoved(shape: Konva.Text) {
        this.sendMessageToRoom("remove", new ShapeData({
            id: shape.id(),
            shape: "text"
        }));
        try {
            if (!this.documentService.document || !this.documentService.selectedPage) throw new Error("展開されている資料がありません．");
            const resuponse = await Axios.delete(`/api/docs/${this.documentService.document.docId}/pages/${this.documentService.selectedPage.xdpId}/markers/${shape.id()}`);
        }
        catch (ex) {
            logger.error("マーカーの送信に失敗しました", ex);
            console.error("マーカーの送信に失敗しました", ex);
        }
    }

    private async onLineRemoved(shape: Konva.Line) {
        this.sendMessageToRoom("remove", new ShapeData({
            id: shape.id(),
            shape: "line"
        }));
        try {
            if (!this.documentService.document || !this.documentService.selectedPage) throw new Error("展開されている資料がありません．");
            const resuponse = await Axios.delete(`/api/docs/${this.documentService.document.docId}/pages/${this.documentService.selectedPage.xdpId}/markers/${shape.id()}`);
        }
        catch (ex) {
            logger.error("マーカーの送信に失敗しました", ex);
            console.error("マーカーの送信に失敗しました", ex);
        }
    }

    private async onArrowRemoved(shape: Konva.Arrow) {
        this.sendMessageToRoom("remove", new ShapeData({
            id: shape.id(),
            shape: "arrow",
            points: shape.points(),
            stroke: shape.stroke(),
            strokeWidth: shape.strokeWidth(),
            width: this.canvas.width(),
            height: this.canvas.height(),
            scaleX: shape.scaleX(),
            scaleY: shape.scaleY(),
            x: shape.x(),
            y: shape.y(),
            rotation: shape.rotation()
        }));
        try {
            if (!this.documentService.document || !this.documentService.selectedPage) throw new Error("展開されている資料がありません．");
            const resuponse = await Axios.delete(`/api/docs/${this.documentService.document.docId}/pages/${this.documentService.selectedPage.xdpId}/markers/${shape.id()}`);
        }
        catch (ex) {
            logger.error("マーカーの送信に失敗しました", ex);
            console.error("マーカーの送信に失敗しました", ex);
        }
    }

    private onRectMoved(shape: Konva.Rect) {
        this.sendMessageToRoom("move", new ShapeData({
            id: shape.id(),
            shape: "rect",
            points: [shape.x(), shape.y(), shape.width(), shape.height()],
            stroke: shape.stroke(),
            strokeWidth: shape.strokeWidth(),
            width: this.canvas.width(),
            height: this.canvas.height(),
            scaleX: shape.scaleX(),
            scaleY: shape.scaleY(),
            x: shape.x(),
            y: shape.y(),
            rotation: shape.rotation()
        }));
    }

    private onTextMoved(shape: Konva.Text) {
        this.sendMessageToRoom("move", new ShapeData({
            id: shape.id(),
            shape: "text",
            points: [shape.x(), shape.y(), shape.width(), shape.height()],
            text: shape.text(),
            stroke: shape.stroke(),
            strokeWidth: shape.strokeWidth(),
            width: this.canvas.width(),
            height: this.canvas.height(),
            scaleX: shape.scaleX(),
            scaleY: shape.scaleY(),
            x: shape.x(),
            y: shape.y(),
            rotation: shape.rotation()
        }));
    }

    private onLineMoved(shape: Konva.Line) {
        this.sendMessageToRoom("move", new ShapeData({
            id: shape.id(),
            shape: "line",
            points: shape.points(),
            stroke: shape.stroke(),
            strokeWidth: shape.strokeWidth(),
            width: this.canvas.width(),
            height: this.canvas.height(),
            scaleX: shape.scaleX(),
            scaleY: shape.scaleY(),
            x: shape.x(),
            y: shape.y(),
            rotation: shape.rotation()
        }));
    }

    private onArrowMoved(shape: Konva.Arrow) {

    }

    /**
     * WebSocketでルーム全員にメッセージを送信します．
     * @param mode メッセージの種類
     * @param data 送信するデータ
     */
    private sendMessageToRoom(mode: string, data: any) {
        this.webSocketService.send("broadcastToRoom", new WebSocketMessage(mode, data, uid));
    }

    /**
     * WebSocketサーバーへ接続してメッセージを監視します．
     * @param roomId ルームID
     * @param groupId グループID
     * @param token アクセストークン
     */
    public async serve(roomId: number, groupId?: number, token?: string): Promise<void> {
        try {
            this.webSocketService.onRecieved.add(e => {
                const shape = e.data as ShapeData;
                if (e.clientHash === uid) return;
                if ((e.type === "add" || e.type === "move")) {
                    if (e.type === "move") {
                        const s = this.layer.findOne("#" + shape.id);
                        s.remove();
                    }
                    if (shape.shape === "line") {
                        this.layers[DrawingModeType.Line].add(new Konva.Line({
                            points: this.coordinate(shape.points, this.canvas.width() / shape.width, this.canvas.height() / shape.height),
                            stroke: shape.stroke,
                            strokeWidth: shape.strokeWidth,
                            lineCap: "round",
                            lineJoin: "round",
                            draggable: true,
                            scaleX: shape.scaleX,
                            scaleY: shape.scaleY,
                            x: shape.x,
                            y: shape.y,
                            rotation: shape.rotation,
                            id: shape.id
                        }));
                    }
                    else if (shape.shape === "rect") {
                        const rect = this.coordinate(shape.points, this.canvas.width() / shape.width, this.canvas.height() / shape.height);
                        this.layers[DrawingModeType.Line].add(new Konva.Rect({
                            x: rect[0],
                            y: rect[1],
                            stroke: shape.stroke,
                            strokeWidth: shape.strokeWidth,
                            draggable: true,
                            scaleX: shape.scaleX,
                            scaleY: shape.scaleY,
                            rotation: shape.rotation,
                            width: rect[2],
                            height: rect[3],
                            id: shape.id
                        }));
                    }
                    if (shape.shape === "arrow") {
                        this.layers[DrawingModeType.Line].add(new Konva.Arrow({
                            points: this.coordinate(shape.points, this.canvas.width() / shape.width, this.canvas.height() / shape.height),
                            stroke: shape.stroke,
                            strokeWidth: shape.strokeWidth,
                            draggable: true,
                            scaleX: shape.scaleX,
                            scaleY: shape.scaleY,
                            fillPatternOffsetX: shape.x,
                            y: shape.x,
                            rotation: shape.rotation,
                            id: shape.id
                        }));
                    }
                    if (shape.shape === "text") {
                        const rect = this.coordinate(shape.points, this.canvas.width() / shape.width, this.canvas.height() / shape.height);
                        this.layers[DrawingModeType.Line].add(new Konva.Text({
                            x: rect[0],
                            y: rect[1],
                            stroke: shape.stroke,
                            strokeWidth: shape.strokeWidth,
                            draggable: true,
                            scaleX: shape.scaleX,
                            scaleY: shape.scaleY,
                            rotation: shape.rotation,
                            id: shape.id,
                            width: rect[2],
                            height: rect[3],
                            text: shape.text
                        }));
                    }
                }
                else if (e.type === "remove") {
                    const s = this.layers[DrawingModeType.Line].find("#" + shape.id) as Konva.Shape;
                    s.remove();
                    this.canvas.draw();
                }
                else if (e.type === "removeAll") {
                    for (const key in Object.keys(DrawingModeType)) {
                        this.layers[DrawingModeType.Line].removeAllShape();
                    }
                }
                else if (e.type === "zoom") {
                    this.recieveZoomHandler.next(e.data as any);
                }
                else if (e.type === "isZoomEnabled") {
                    this.zoomEnabledHandler.next(e.data as any);
                }
                this.canvas?.draw();
                console.log("redraw");
            });
        }
        catch {
            console.error("failed websocket in drawing");
        }
    }

    // #region helper methods
    /**
     * 数値を指定した範囲内に正規化します．
     */
    private sturate(n: number, r1: number, r2: number): number {
        const r = r1 < n ? (n < r2 ? n : r2) : 0;
        return r;
    }
    // #endregoin
}

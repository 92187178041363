import Axios from "axios";

import { Document } from "./Document";
import { IDocument } from "./IDocument";
import { SuccessResponse } from "../api/Response";
import { IDocumentPage } from "./IDocumentPage";
import { from } from "linq";
import { EditableDocumentPage } from "./EditableDocumentPage";
import { DocumentPage } from "./DocumentPage";
import { delay } from "../utilities/timer";

/**
 * 資料の編集を提供します．
 */
export class DocumentEditService {
    // #regin fields
    private _document: Document<EditableDocumentPage> | null = null;
    // #endregion

    // //#region  properties
    public get document(): Document<EditableDocumentPage> | null {
        return this._document;
    }
    public set document(value: Document<EditableDocumentPage> | null) {
        this._document = value;
    }
    // #endregion

    // #region methods
    /**
     * ファイルをサーバーにアップロードします．
     * @param file アップロードするファイル
     * @returns アップロード先のURL
     */
    public async uploadMediaFile(file: File): Promise<{ url: string; docId: string; } | undefined> {
        try {
            const params = new FormData();
            params.append("file", file);
            const result = await Axios.post<SuccessResponse<{ doc: IDocument<never>, file: any }>>("/api/docs", params);

            while (true) {
                const resoponse = await Axios.get<SuccessResponse<{ split: number; convert: number; }>>(`/api/docs/${result.data.data.doc.docId}/status`);
                if (resoponse.data.data.split === 0 && resoponse.data.data.convert === 0) break;
                await delay(1000);
            }

            return { url: result.data.data.doc.thumbnailUrl, docId: result.data.data.doc.docId };
        }
        catch (ex) {
            logger.error("ファイルのアップロードに失敗しました", ex);
            console.error("ファイルのアップロードに失敗しました", ex);
        }
        return undefined;
    }

    public async savePage(docId: string, page: EditableDocumentPage): Promise<boolean> {
        try {
            const result = await Axios.put<SuccessResponse<unknown>>(`/api/docs/${docId}/pages/${page.page.xdpId}`, page.page);
            return true;
        }
        catch (ex) {
            logger.error("ファイルのアップロードに失敗しました", ex);
            console.error("ファイルのアップロードに失敗しました", ex);
        }
        return false;
    }

    public async createNewPage(docId: string, index: number, file: File): Promise<boolean> {
        try {
            const media = await this.uploadMediaFile(file);
            if (!media) throw new Error("failed to upload media.");
            const page = {
                fromDocId: media.docId,
                index,
                page: 1,
                password: "",
                remarks: ""
            };
            const result = await Axios.post<SuccessResponse<IDocumentPage>>(`/api/docs/${docId}/pages`, page);
            return true;
        }
        catch (ex) {
            logger.error("ファイルのアップロードに失敗しました", ex);
            console.error("ファイルのアップロードに失敗しました", ex);
        }
        return false;
    }

    public async removePage(docId: string, xpgId: string) {
        try {
            const result = await Axios.delete(`/api/docs/${docId}/pages/${xpgId}`);
            return result.data.data;
        }
        catch (ex) {
            logger.error("ファイルの削除に失敗しました", ex);
            console.error("ファイルの削除に失敗しました", ex);
        }
        return undefined;
    }

    /**
     * 編集する資料をサーバーから取得します．
     * @param docId 編集する資料ID
     * @returns 成功したかどうか
     */
    public async fetch(docId: string): Promise<Document<EditableDocumentPage> | null> {
        try {
            const result = await Axios.get<SuccessResponse<Document<IDocumentPage>>>("/api/docs/" + docId);
            this._document = new Document<EditableDocumentPage>(result.data.data, from(result.data.data.pages).select(x => new EditableDocumentPage(x, false)).toArray());
            return this.document;
        }
        catch (ex) {
            logger.error(ex);
            console.error(ex);
        }
        return null;
    }
    // #endregion
}

import { IDocumentPage } from "./IDocumentPage";

/**
 * 編集可能な資料ページを提供します．
 */
export class EditableDocumentPage {
    isModified = false;
    isNew = true;
    index = 0;
    page: IDocumentPage;

    /**
     * コンストラクタ
     * @param page 初期値
     */
    constructor(page: IDocumentPage, isNew = true) {
        this.page = page;
        this.isNew = isNew;
    }
}

export class WebSocketMessage<T> {
    type: string;
    data: T;
    clientHash: string;
    public constructor(type: string, data: T, clientHash = "") {
        this.data = data;
        this.type = type;
        this.clientHash = clientHash;
    }
}

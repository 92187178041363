import { IRoomInfo } from "@/models/room/IRoomInfo";
import { ScreenModeType } from "@/models/room/ConnectionService";

/**
 * ルーム情報を表します.
 */
export class RoomInfo implements IRoomInfo {
    roomId = 0;
    name = "未指定";
    slot = 1;
    connectionNumber = "";
    keyHash = "";
    useSfu = true;
    status: "connecting" | "waiting" | "destroyed" = "waiting";
    groupId = 1;
    createdBy = 1;
    createdAt = "";
    updatedBy = 1;
    updatedAt = "";
    currentDocId: string | null = null;
    mode: "documents" | "meeting" | "voice" = "meeting";

    /**
     * コンストラクタ
     * @param info 初期値
     */
    constructor(info?: Partial<IRoomInfo>) {
        if (!info) return;
        Object.assign(this, info);
    }
    currentDocPage = null;
    currentScreen = null;
}

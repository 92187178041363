/**
 * @summary ダイアログに表示するためのコンテントを表します.
 */
export class ConfirmDialogContent {
    text = "";
    title = "";
    okText = "OK";
    cancelText = "キャンセル";
    hideCancelButton = false;

    /**
     * @summary コンストラクタ
     * @param 初期値
     */
    constructor(content: Partial<ConfirmDialogContent>) {
        Object.assign(this, content);
    }
}

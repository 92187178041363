import { WebSocketService } from "@/models/web-socket/WebSocketService";
import { WebSocketMessage } from "../web-socket/WebSocketMessage";
import { IChatMessage } from "./IChatMessage";
import { DateTime } from "luxon";
import { MultiCastDelegate } from "ui-gallery";
import { v4 } from "uuid";

const hash = v4();

export class ChatService {
    messageList: IChatMessage[] = [];
    public messageRecieved = new MultiCastDelegate<(e: IChatMessage) => void>();

    constructor(private readonly webSocketService: WebSocketService<WebSocketMessage<IChatMessage>>) {

    }

    public send(message: IChatMessage) {
        this.messageList.push(message);
        this.webSocketService.send("broadcastToRoom", new WebSocketMessage("chat", message, hash));
    }

    public async serve(roomId: number): Promise<void> {
        this.webSocketService.onRecieved.add(x => {
            if (x.type === "chat" && x.clientHash !== hash) {
                const message = {
                    clientHash: x.data.clientHash,
                    name: x.data.name,
                    message: x.data.message,
                    time: DateTime.fromISO(x.data.time as any)
                };
                this.messageRecieved.invoke(message);
                this.messageList.push(message);
            }
        });
    }
}
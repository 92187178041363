<template>
    <v-dialog
        v-model="isShow"
        :content-class="id"
        hide-overlay
        ref="menu"
        :max-width="maxWidth"
    >
        <v-card ref="card">
            <slot />
        </v-card>
    </v-dialog>
</template>
<script lang="ts">
/**
 * @packageDocumentation
 * @module Components
 * @preferred
 */
import { Vue, Component, Prop } from "vue-property-decorator";
import { VCard, VDialog } from "vuetify/lib";
import { v4 } from "uuid";
import { delay } from "../core/tasks/timer";
/**
 * 任意の位置に表示するコンテキストメニューを提供します．
 */
@Component({ components: { VCard, VDialog } })
export default class ContextMenu extends Vue {
    private isShow = false;
    private id = v4();

    /**
     * 最大Width
     */
    @Prop({ default: "248" }) readonly maxWidth!: number;

    /**
     * 指定した絶対座標にコンテキストメニューを表示します
     * @param x x座標
     * @param y y座標
     */
    public show(x: number, y: number) {
        this.isShow = true;
        this.$nextTick(async () => {
            const menu = this.$refs.menu as Vue | undefined;
            if (!menu) return;
            (menu.$el as HTMLElement).style.opacity = "0";

            // とりあえず近くまで寄せておく
            const element = document.getElementsByClassName(`${this.id}`)[0] as HTMLElement;
            element.style.position = "fixed";
            element.style.left = (Math.max(0, x)).toString() + "px";
            element.style.top = (Math.max(0, y)).toString() + "px";

            // 高さが変わるまで待つ
            await delay(200);
            (menu.$el as HTMLElement).style.opacity = "1";
            const rect = (this.$refs.card as Vue).$el.getBoundingClientRect();
            element.style.left = (Math.max(0, Math.min(window.innerWidth - Number(this.maxWidth) - 40, x))).toString() + "px";
            element.style.top = (Math.max(0, Math.min(window.innerHeight - rect.height - 40, y))).toString() + "px";
        });
    }

    public close() {
        this.isShow = false;
    }
}
</script>
<style>
</style>

import axios from "axios";
import { singleton, inject } from "tsyringe";
import { WebSocketService } from "@/models/web-socket/WebSocketService";
import { RoomInfoService } from "@/models/room/RoomInfoService";
import { AuthService } from "@/models/auth/AuthService";
import { WebSocketMessage } from "@/models/web-socket/WebSocketMessage";
import { MultiCastDelegate } from "ui-gallery";
import { RoomInfo } from "@/models/room/RoomInfo";
import { container } from "tsyringe";
import { IRoomInfo } from "@/models/room/IRoomInfo";

export enum ScreenModeType {
    VideoChat = 1,
    DocumentShare = 2,
    ScreenShare = 3
}

/**
 * ルーム情報に関するサービスを提供します.
 */
export class ConnectionService {
    // #regoin private fields
    private _screenMode: ScreenModeType = ScreenModeType.VideoChat;
    private _nickName = "";
    private _roomInfo: IRoomInfo = new RoomInfo();
    // #endregion

    // #region public gettters
    /**
     * 接続終了メッセージを受け取ったときに実行されるイベントハンドラ
     */
    public readonly connectionCloseRecieved = new MultiCastDelegate<() => void>();

    private readonly roomInfoService = container.resolve(RoomInfoService);

    /**
     * 画面モード
     */
    public get screenMode(): ScreenModeType {
        return this._screenMode;
    }
    public set screenMode(value: ScreenModeType) {
        this._screenMode = value;
    }

    /**
     * ニックネーム
     */
    public get nickName(): string {
        return this._nickName;
    }
    public set nickName(name: string) {
        this._nickName = name;
    }
    // #endregion

    // #region mutations
    public setScreenMode(type: ScreenModeType) {
        this.sendMessageToRoom("screenMode", { mode: type });
        this.saveCurrentScreen(type);
        this._screenMode = type;
    }

    /**
     * ルーム情報をセットします．
     * @param value ルーム情報
     */
    public setRoom(value: IRoomInfo) {
        this._roomInfo = value;
    }
    // #endregion

    // #region methods
    /**
     * コンストラクタ
     */
    public constructor(readonly webSocketService: WebSocketService<WebSocketMessage<any>>) {
    }

    /**
     * 接続を終了します．
     */
    public closeConnection() {
        this.sendMessageToGroup("rooms", undefined);
        this.sendMessageToRoom("shut", this._roomInfo);
    }

    private saveCurrentScreen(type: ScreenModeType) {
        try {
            const room = this.roomInfoService.roomInfo;
            if (!room) {
                throw new Error("room not found in seve screen mode.");
            }
            room.currentScreen = type;
            this.roomInfoService.updateRoom(room);
        }
        catch (ex) {
            console.error(ex);
        }
    }

    /**
     * WebSocketサーバーへ接続してメッセージを監視します．
     * @param roomId ルームID
     * @param groupId グループID
     * @param token アクセストークン
     */
    public async serve(roomId: number, groupId?: number, token?: string): Promise<void> {
        try {
            this.webSocketService.onRecieved.add(e => {
                if (e.type === "screenMode") {
                    this._screenMode = e.data.mode;
                }
                else if (e.type === "shut" && e.data.roomId === this._roomInfo.roomId) {
                    this.connectionCloseRecieved.invoke([]);
                }
            });
        }
        catch (ex) {
            console.error("failed websocket in connection service", ex);
        }
    }

    /**
     * WebSocketでルーム全員にメッセージを送信します．
     * @param mode メッセージの種類
     * @param data 送信するデータ
     */
    private sendMessageToRoom(mode: string, data: any) {
        this.webSocketService.send("broadcastToRoom", new WebSocketMessage(mode, data));
    }

    /**
     * WebSocketでグループメンバー全員にメッセージを送信します．
     * @param mode メッセージの種類
     * @param data 送信するデータ
     */
    private sendMessageToGroup(mode: string, data: any) {
        this.webSocketService.send("broadcastToGroup", new WebSocketMessage(mode, data));
    }
    // #endregion
}

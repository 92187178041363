<template>
    <div
        class="split-view"
        :style="getStyle()"
        ref="container"
    >
        <!-- @slot 分割する際の最初のスロット -->
        <slot name="slot1" />
        <div
            :style="{cursor:isVertical?'ns-resize':'ew-resize',background:handleColor}"
            @mousedown="startDrag"
            @touchstart="touchStart"
            class="handle"
        ></div>
        <!-- @slot 分割する際のもう一方のスロット -->
        <slot name="slot2" />
    </div>
</template>
<script lang="ts">
/**
 * @packageDocumentation
 * @module Components
 * @preferred
 */
import { Vue, Prop, Component, Watch } from "vue-property-decorator";

/**
 * リサイズ可能な分割ビューを提供します．
 */
@Component({ components: {} })
export default class SplitView extends Vue {
    private isDragging = false;
    private splitWidth = 0;
    // タッチの移動量算出用
    private touchStartY = 0;
    private touchStartX = 0;

    /**
     * デフォルトの分割長さ
     */
    @Prop({ default: 180 }) readonly defaultSplitWidth!: number;

    /**
     * 縦か横か
     */
    @Prop({ default: false }) readonly isVertical!: boolean;

    /**
     * 最小長さ
     */
    @Prop({ default: 0 }) readonly minWidth!: number;

    /**
     * 最大長さ
     */
    @Prop({ default: Number.MAX_SAFE_INTEGER }) readonly maxWidth!: number;

    /**
     * ハンドルのサイズ
     */
    @Prop({ default: 4 }) readonly handleSize!: number;

    /**
     * ハンドルカラー
     */
    @Prop({ default: "rgba(123, 123, 123, 0.53)" }) readonly handleColor!: string;

    /**
     * どちらのスロットを基底とするか
     * @description 規定では最初のスロットが基底となっています．リサイズ等をしたときに規定のほうのサイズは変わらずもう片方のサイズが自動算出されます．
     */
    @Prop({ default: false }) readonly inverse!: boolean;

    @Watch("isVertical")
    private onIsVerticalChangrd() {
        this.$nextTick(() => {
            this.splitWidth = this.defaultSplitWidth;
        });
    }

    private created() {
        this.splitWidth = this.defaultSplitWidth;
        window.addEventListener("mousemove", this.onMouseMove);
        window.addEventListener("mouseup", this.onMouseUp);
        window.addEventListener("touchmove", this.touchMove);
        window.addEventListener("touchend", this.onMouseUp);
    }

    private beforeDestroy() {
        window.removeEventListener("mousemove", this.onMouseMove);
        window.removeEventListener("mouseup", this.onMouseUp);
        window.removeEventListener("touchmove", this.touchMove);
        window.removeEventListener("touchend", this.onMouseUp);
    }

    getStyle() {
        if (this.inverse) {
            return {
                gridTemplateColumns: !this.isVertical ? `calc(100% - ${Number(this.splitWidth) + Number(this.handleSize)}px) ${this.handleSize}px ${this.splitWidth}px` : "100%",
                gridTemplateRows: this.isVertical ? `calc(100% - ${Number(this.splitWidth) + Number(this.handleSize)}px) ${this.handleSize}px ${this.splitWidth}px` : "100%"
            };
        }
        return {
            gridTemplateColumns: !this.isVertical ? `${this.splitWidth}px ${this.handleSize}px calc(100% - ${Number(this.splitWidth) + Number(this.handleSize)}px) ` : "100%",
            gridTemplateRows: this.isVertical ? `${this.splitWidth}px ${this.handleSize}px calc(100% - ${Number(this.splitWidth) + Number(this.handleSize)}px) ` : "100%"
        };
    }

    /**
     * ドラッグを開始します.
     */
    private startDrag() {
        this.isDragging = true;
    }

    /**
     * タッチが開始されたとき．
     */
    private touchStart(e: TouchEvent) {
        this.touchStartY = e.touches[0].pageY;
        this.isDragging = true;
    }

    /**
     * マウスが動いたとき.
     * @description 分割するサイズを変更します．
     */
    private onMouseMove(e: MouseEvent) {
        if (this.isDragging) {
            this.applyMovement(e.movementX, e.movementY);
        }
    }

    /**
     * タッチしている指が動いたとき．
     */
    private touchMove(e: TouchEvent) {
        if (this.isDragging) {
            e.preventDefault();
            const touchMoveY = e.changedTouches[0].pageY - this.touchStartY;
            this.touchStartY = e.changedTouches[0].pageY;
            const touchMoveX = e.changedTouches[0].pageX - this.touchStartX;
            this.touchStartX = e.changedTouches[0].pageX;
            this.applyMovement(touchMoveX, touchMoveY);
        }
    }

    /**
     * マウスの移動量を分割する大きさに適用します．
     */
    private applyMovement(movementX: number, movementY: number) {
        const container = this.$refs.container as HTMLElement | undefined;
        if (!container) return;
        const rect = container.getBoundingClientRect();
        if (this.isVertical) {
            if (this.inverse) this.splitWidth -= movementY;
            else this.splitWidth += movementY;
            this.splitWidth = Math.max(this.minWidth, Math.min(this.splitWidth, Math.min(this.maxWidth, rect.height - this.handleSize)));
        }
        else {
            if (this.inverse) this.splitWidth -= movementX;
            else this.splitWidth += movementX;
            this.splitWidth = Math.max(this.minWidth, Math.min(this.splitWidth, Math.min(this.maxWidth, rect.width - this.handleSize)));
        }
    }

    /**
     * マウスがクリックをやめたとき．
     * @description ドラッグフラグを解除します．
     */
    private onMouseUp() {
        this.isDragging = false;
    }
}
</script>
<style lang="scss" scoped>
.split-view {
    display: grid;
    overflow: hidden;
}

.handle {
    cursor: ew-resize;
    user-select: none;
}

.handle:hover {
    background: rgba(60, 60, 60, 0.53);
}
</style>

import { ISetting } from "./ISetting";

export class Setting implements ISetting {
    readonly groupId = 0;
    readonly gsId = "";
    readonly roomNum = 0;
    readonly skywayKey: string | null = null;
    readonly useSfu = false;

    constructor(params?: Partial<ISetting>) {
        Object.assign(this, params);
    }
}

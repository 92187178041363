<template>
    <transition-group
        name="reorder"
        class="item-wrap-grid"
        @resize="onResize"
        tag="div"
    >
        <div
            v-for="(item,key) in items"
            :style="{width:`calc(100% / ${columnCount})`}"
            class="item"
            :key="key"
        >
            <slot
                name="item"
                :index="key"
                :item="item"
            ></slot>
        </div>

        <div
            :style="{width:`calc(100% / ${columnCount})`}"
            class="item"
        >
            <slot
                name="last-item-area"
                :colmunCount="columnCount"
            ></slot>
        </div>
    </transition-group>
</template>
<script lang="ts">
/**
 * @packageDocumentation
 * @module Components
 * @preferred
 */
import { Vue, Prop, Component } from "vue-property-decorator";

/**
 * アイテムを折り返し表示するためのグリッドレイアウトを提供します．
 */
@Component
export default class ItemWrapGrid extends Vue {
    private columnCount = 0;
    /**
     * 表示するアイテム
     */
    @Prop({ default: [] }) readonly items!: unknown[];

    /**
     * wrapする条件
     */
    @Prop({
        default: () => [
            {
                width: 1280,
                columnCount: 4
            },
            {
                width: 780,
                columnCount: 3
            },
            {
                width: 580,
                columnCount: 2
            },
            {
                width: 0,
                columnCount: 1
            }
        ]
    }) readonly breakWidths!: any[];

    public updated() {
        this.$nextTick(() => (this.onResize()));
    }

    private mounted() {
        window.addEventListener("resize", this.onResize);
        setTimeout(() => (this.$nextTick(() => (this.onResize()))), 100);
    }

    private beforeDestroy() {
        window.removeEventListener("resize", this.onResize);
    }

    private onResize() {
        setTimeout(() => {
            const width = this.$el.getBoundingClientRect().width;
            for (const item of this.breakWidths) {
                if (item.width < width) {
                    this.columnCount = item.columnCount;
                    return;
                }
            }
        }, 100);
    }
}
</script>
<style scoped>
.item-wrap-grid {
    display: flex;
    flex-wrap: wrap;
}
.reorder-move {
    transition: transform 0.5s;
}
</style>

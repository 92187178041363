export enum QualityType {
    High,
    Middle,
    Low
}

/**
 * 接続設定情報を提供します.
 */
export class ConnectionSetting {
    // #region public fields
    public roomId = 0;
    public audioDeviceId = "";
    public videoDeviceId = "";
    public outputDeviceId = "";
    public mode: "documents" | "meeting" | "voice" = "meeting";
    public quality = QualityType.High;
    public useSfu = true;
    public roomName = "";
    public nickName = "";
    public isAudioEnabled = false;
    public isVideoEnabled = false;
    public connectionNumber = "";
    public status: "connecting" | "waiting" | "destroyed" = "waiting";
    // #endregion

    // #region public methods
    public constructor(connectionSetting?: Partial<ConnectionSetting>) {
        Object.assign(this, connectionSetting);
    }
    // #endregin
}

import Axios from "axios";
import { SuccessResponse } from "../api/Response";
import { IDocument } from "./IDocument";
import { Document } from "./Document";
import { from } from "linq";
import { IDocumentPage } from "./IDocumentPage";
import { delay } from "@/models/utilities/timer";
import { IDocumentGroup } from "./IDocumentGroup";

export class DocumentManagementsService {
    /**
     * 資料一覧
     */
    public documents: IDocument<IDocumentPage>[] = [];

    /**
     * フォルダ一覧
     */
    public documentGroups: IDocumentGroup[] = [];

    public activeGroup: IDocumentGroup | null = null;

    /**
     * ファイルをアップロードします．
     * @param file アップロードするファイルします．
     */
    public async uploadFileAsync(file: File, dgId: string, password: string): Promise<boolean> {
        try {
            const params = new FormData();
            params.append("file", file);
            const res = await Axios.post<SuccessResponse<any>>("/api/docs", params);
            const doc = res.data.data.doc as IDocument<IDocumentPage>;

            while (true) {
                const resoponse = await Axios.get<SuccessResponse<{ split: number; convert: number; }>>(`/api/docs/${doc.docId}/status`);
                if (resoponse.data.data.split === 0 && resoponse.data.data.convert === 0) break;
                await delay(1000);
            }

            await this.publishDocument(doc.docId, dgId, password);
            await this.fetchDocumentGroupsAsync();
            return true;
        }
        catch (ex) {
            console.error("ファイルのアップロードに失敗しました．", ex);
            logger.log("ファイルのアップロードに失敗しました。", ex);
            throw new Error(ex.response.data.message);
        }
    }

    /**
     * グループを新規作成します．
     * @param name 新規作成するフォルダ名
     */
    public async createNewGroupAsync(name: string): Promise<boolean> {
        try {
            const result = await Axios.post("/api/doc-groups", { name });
            return true;
        }
        catch (ex) {
            console.error("フォルダの作成に失敗しました", ex);
            logger.error(["フォルダの作成に失敗しました", ex]);
        }
        return false;
    }

    /**
     * 資料を公開します．
     * @param docId 資料ID
     * @param oldPassword 現在のパスワード
     * @param newPassword 新しいパスワード
     */
    public async changePasswordAsync(docId: string, oldPassword: string, newPassword: string): Promise<boolean> {
        try {
            await Axios.post(`/api/docs/${docId}/password`, {
                password: oldPassword,
                newPassword
            });
            await this.fetchDocumentGroupsAsync();
            return true;
        }
        catch (ex) {
            console.error("フォルダ名の変更に失敗しました", ex);
            logger.error(["フォルダ名の変更に失敗しました", ex]);
        }
        return false;
    }

    /**
     * 資料を公開します．
     * @param docId 資料ID
     * @param password パスワード
     */
    public async publishDocument(docId: string, dgId: string, newPassword: string): Promise<boolean> {
        try {
            const result = await Axios.post<SuccessResponse<IDocument<IDocumentPage>>>("/api/docs/" + docId + "/publish", { newPassword });
            const newDoc = result.data.data;
            await this.removeDocumentAsync(docId);
            await this.addDocumentToGroup(newDoc.docId, dgId);
            return true;
        }
        catch (ex) {
            console.error("資料の公開に失敗しました", ex);
            logger.error(["資料の公開に失敗しました", ex]);
        }
        return false;
    }

    /**
     * グループを削除します．
     * @param dgIp グループID
     */
    public async removeGroup(dgIp: string): Promise<boolean> {
        try {
            await Axios.delete("/api/doc-groups/" + dgIp);
            return true;
        }
        catch (ex) {
            console.error("フォルダの作成に失敗しました", ex);
            logger.error(["フォルダの作成に失敗しました", ex]);
        }
        return false;
    }

    /**
     * フォルダ名を変更します．
     * @param name グループID
     */
    public async renameGroup(dgId: string, name: string): Promise<boolean> {
        try {
            await Axios.put("/api/doc-groups/" + dgId, {
                name
            });
            await this.fetchDocumentGroupsAsync();
            return true;
        }
        catch (ex) {
            console.error("フォルダの作成に失敗しました", ex);
            logger.error(["フォルダの作成に失敗しました", ex]);
        }
        return false;
    }

    /**
     * フォルダに資料を追加します．
     * @param docId 資料ID
     * @param dgId ドキュメントグループID
     */
    public async addDocumentToGroup(docId: string, dgId: string): Promise<boolean> {
        try {
            await Axios.post<SuccessResponse<any>>("/api/doc-groups/" + dgId, { docIds: [docId] });
            return true;
        }
        catch (ex) {
            console.error("資料のフォルダへの追加に失敗しました．", ex);
            logger.log("資料のフォルダへの追加に失敗しました", ex);
        }
        return false;
    }

    /**
     * 資料名を変更します．
     * @param name グループID
     */
    public async renameDocument(docId: string, name: string): Promise<boolean> {
        try {
            await Axios.put("/api/docs/" + docId, {
                name
            });
            await this.fetchDocumentGroupsAsync();
            return true;
        }
        catch (ex) {
            console.error("フォルダ名の変更に失敗しました", ex);
            logger.error(["フォルダ名の変更に失敗しました", ex]);
        }
        return false;
    }

    /**
     * 資料一覧を取り込みます．
     * @returns 成功したかどうか
     */
    public async fetchDocumentGroupsAsync(): Promise<boolean> {
        try {
            const result2 = await Axios.get<SuccessResponse<IDocumentGroup[]>>("/api/doc-groups");
            this.documentGroups = from(result2.data.data).select(x => {
                x.docs = from(x.docs).orderByDescending(x => x.createdAt).toArray();
                return x;
            }).orderBy(x => x.name).toArray();
            if (this.activeGroup) {
                this.activeGroup = from(this.documentGroups).where(x => x.dgId === (this.activeGroup ? this.activeGroup.dgId : "")).firstOrDefault() ?? null;
            }
            else {
                this.activeGroup = this.documentGroups[0] || {};
            }
            return true;
        }
        catch (ex) {
            logger.error(ex);
            console.error(ex);
        }
        return false;
    }

    /**
     * 資料を１件取り込みます．
     * @param docId 資料ID
     */
    public async fetchDocumentAsync(docId: string): Promise<Document<IDocumentPage> | undefined> {
        try {
            const result = await Axios.get<SuccessResponse<Document<IDocumentPage>>>("/api/docs/" + docId);
            return new Document(result.data.data);
        }
        catch (ex) {
            console.error(ex);
            logger.error(ex);
        }
        return undefined;
    }

    /**
     * 資料を削除します．
     * @param docId 資料ID
     * @param password パスワード
     */
    public async removeDocumentAsync(docId: string, password?: string): Promise<boolean> {
        try {
            const result = await Axios.delete("/api/doc-groups/" + (this.activeGroup ? this.activeGroup.dgId : "") + "/docs/" + docId, {
                params: {
                    password
                }
            });
            await Axios.delete("/api/docs/" + docId, {
                params: {
                    password
                }
            });
            return true;
        }
        catch (ex) {
            console.error(ex);
            logger.error(ex);
        }
        return false;
    }
}

<template>
    <v-menu bottom origin="center center" transition="scale-transition">
        <template v-slot:activator="{ on }">
            <slot name="activator" :on="on" />
        </template>
        <v-item-group  mandatory :value="value">
            <v-card :style="{background:backgroundColor}">
                <div
                    class="d-flex flex-wrap"
                    :style="{width:((itemSize+margin)*column+margin*2).toString()+'px',padding:margin+'px'}"
                >
                    <v-item
                        v-slot:default="{ active, toggle }"
                        v-for="(c,key) in colors"
                        :key="key"
                    >
                        <div
                            v-ripple
                            class="palette"
                            :class="value===c?'active':''"
                            @click.prevent="$emit('input',c)"
                            :style="{background:c,height:itemSize+'px',width:itemSize+'px'}"
                            style="margin:1px;"
                        ></div>
                    </v-item>
                </div>
            </v-card>
        </v-item-group>
    </v-menu>
</template>
<script lang="ts">
/**
 * @packageDocumentation
 * @module Components
 * @preferred
 */
import { Vue, Component, Prop } from "vue-property-decorator";
import { VMenu, VItemGroup, VCard, VItem } from "vuetify/lib";

const defaultColors = [
    "#f44336",
    "#FF9800",
    "#ff9800",
    "#ffeb3b",
    "#CDDC39",
    "#8BC34A",
    "#4caf50",
    "#009688",
    "#00bcd4",
    "#03A9F4",
    "#2196f3",
    "#4527A0",
    "#673AB7",
    "#9c27b0",
    "#ec407a",
    "#909090",
    "#0a0a0a",
    "#FFFFFF"
];

/**
 * 色を選択するためのパレットを提供します．
 */
@Component({ components: { VMenu, VItemGroup, VCard, VItem } })
export default class ColorPaletteFlyout extends Vue {
    /**
     * 選択させる色一覧
     */
    @Prop({ default: () => defaultColors }) colors!: string[];

    /**
     * 初期値
     */
    @Prop({ default: defaultColors[0] }) value!: string;

    /**
     * ボタンの大きさ
     */
    @Prop({ default: 48 }) readonly itemSize!: number;

    /**
     * 折り返すまでの数
     */
    @Prop({ default: 6 }) readonly column!: number;

    /**
     * ボタン同士の隙間の大きさ
     */
    @Prop({ default: 2 }) readonly margin!: number;

    /**
     * パレットの背景色
     */
    @Prop({ default: "#2b2b2b" }) readonly backgroundColor!: string;

    /**
     * コンポーネントが生成されたときに実行されます．
     */
    private created() {
        if (this.value) {
            this.$emit("input", this.value);
            this.$forceUpdate();
        }
        else {
            this.$emit("input", this.colors[0]);
            this.$forceUpdate();
        }
    }
}
</script>
<style scoped>
.active::after {
    background: #ff717100 !important;
    z-index: 333;
    width: 100%;
    height: 100%;
    padding: 0;
    font-size: 36px;
    padding: 6px;
    color: #00000049;
    content: "●";
}

.palette:hover {
    filter: brightness(90%);
}
</style>

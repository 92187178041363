import axios from "axios";
import { SuccessResponse } from "@/models/api/Response";
import Enumerable from "linq";
import { config } from "@/config";
/**
 * エラー報告情報に関するStoreを提供します.
 */
export class AuthService {
    // #region private filelds
    private _token = "";
    private _refreshToken = "";
    private _loginId = "";
    private _expiresIn = "";
    private _memberName = "";
    private _memberId = 0;
    private _isLogin = false;
    private _groupIds: any[] = [];
    private _currentGroupId = 0;
    private _members: { [key: number]: string } = {};
    private _membersList: any[] = [];
    // #endregion

    // #region public properties
    public get members(): { [key: number]: string } {
        return this._members;
    }

    public get membersList() {
        return this._membersList;
    }

    /**
     * アクセストークン
     */
    public get token(): string {
        return this._token;
    }
    public set token(token: string) {
        this._token = token;
    }

    /**
     * リフレッシュトークン
     */
    public get refreshToken(): string {
        return this._refreshToken;
    }
    public set refreshToken(refreshToken: string) {
        this._refreshToken = refreshToken;
    }

    /**
     * 有効期限
     */
    public get expiresIn(): Date {
        return new Date(this._expiresIn);
    }
    public set expiresIn(date: Date) {
        this._expiresIn = date.toString();
    }

    /**
     * メンバー名
     */
    public get memberName(): string {
        return this._memberName;
    }
    public set memberName(name: string) {
        this._memberName = name;
    }

    /**
     * メンバーID
     */
    public get memberId(): number {
        return this._memberId;
    }
    public set memberId(id: number) {
        this._memberId = id;
    }

    /**
     * ログインID（メールアドレス）
     */
    public get loginId(): string {
        return this._loginId;
    }
    public set loginId(id: string) {
        this._loginId = id;
    }

    /**
     * ログインしているかどうか
     */
    public get isLogin(): boolean {
        return this._isLogin;
    }
    public set isLogin(isLogin: boolean) {
        this._isLogin = isLogin;
    }

    /**
     * グループID
     */
    public get groupIds(): { id: number, name: string }[] {
        return this._groupIds;
    }
    public set groupIds(ids: { id: number, name: string }[]) {
        this._groupIds = ids;
    }

    /**
     * 現在選択中のグループID
     */
    public get currentGroupId() {
        return this._currentGroupId;
    }
    public set currentGroupId(id: number) {
        this._currentGroupId = id;
    }
    // #endregion

    // #region methods
    /**
     * コンストラクタ
     */
    public constructor() {
    }

    public async fetchMembers() {
        try {
            const response = await axios.get<SuccessResponse<{ id: number, name: string }[]>>("/api/auth/members");
            if (response.data.status) {
                this._membersList = response.data.data;
                this._members = response.data.data.reduce((x, y) => {
                    x[y.id] = y.name;
                    return x;
                }, {} as { [key: number]: string });
            }
        }
        catch (ex) {
            console.error("メンバー情報の取り込みに失敗しました。", ex);
        }
    }

    /**
     * ログイン中かどうか検証します．
     * @description ローカルストレージ内でのみチェックします．
     * @returns ログイン中かどうか
     */
    public getIsAuthenticated(): boolean {
        if (new Date() < this.expiresIn) {
            return true;
        }
        return false;
    }

    /**
     * リフレッシュトークンを試みます．
     * @returns リフレッシュに成功したかどうか.
     */
    public async tryRefreshToken(): Promise<boolean> {
        try {
            const response = await (await fetch(config.url + "/api/auth", {
                method: "put",
                headers: {
                    Authorization: `Bearer ${this.refreshToken}`
                }
            })).json();
            if (response.status) {
                const data = response.data;
                this.storeUserInfo(data);
                this.isLogin = true;
                return true;
            }
            else {
                throw new Error("failed to refresh token.");
            }
        }
        catch (ex) {
            console.error("トークンの再交付に失敗しました。", ex);
        }
        return false;
    }

    /**
     * ログアウトします．
     * @description ローカルストレージにキャッシュしていた内容はすべて破棄されます．
     */
    public logout() {
        this.refreshToken = "";
        this.token = "";
        this.expiresIn = new Date();
        this.memberId = 0;
        this.memberName = "";
        this.loginId = "";
        this.isLogin = false;
        this.saveStorage();
    }

    /**
     * ローカルストレージに保存しているアクセストークンが有効かどうかサーバーに問い合わせます．
     * @returns アクセストークンが有効かどうか
     */
    public async validateToken(): Promise<boolean> {
        try {
            if (this.token === "") return false;
            const response = await axios.get("/api/auth");
            if (response.data.status) {
                return true;
            }
        }
        catch {
            console.error("トークンが無効です.");
        }
        return false;
    }

    public async fetchMemberInfo() {
        try {
            if (this.token === "") return false;
            const response = await axios.get("/api/auth");
            if (response.data.status) {
                const memberInfo = response.data.data;
                if (memberInfo) {
                    const memberId = Number(memberInfo.id ? memberInfo.id : 0);
                    if (memberId) {
                        this.memberId = memberId;
                    }

                    const memberName = memberInfo.name as string | undefined;
                    if (memberName) {
                        this.memberName = memberName;
                    }

                    const groupIds = memberInfo.groups as { id: number, name: string }[] | undefined;
                    if (groupIds) {
                        this.groupIds = groupIds;

                        // 存在しないグループを選択していたら書き換える
                        if (!Enumerable
                            .from(groupIds)
                            .select(x => x.id)
                            .contains(this.currentGroupId) &&
                            groupIds.length > 0) {
                            this.currentGroupId = Number(groupIds[groupIds.length - 1].id);
                        }
                    }
                }
            }
        }
        catch {
            console.error("トークンが無効です.");
        }
    }

    /**
     * localstorageにデータがあるかloadします
     * @returns データがあるかどうか（あれば取得し変数にセットする）
     */
    public loadStorage(): boolean {
        try{
            this.token = localStorage.getItem("token") || "";
            this.refreshToken = localStorage.getItem("refreshToken") || "";
            const expiresIn = localStorage.getItem("expiresIn") || "";
            this.expiresIn = new Date(expiresIn);
            this.memberName = localStorage.getItem("memberName") || "";
            this.memberId = Number(localStorage.getItem("memberId"));
            this.loginId = localStorage.getItem("loginId") || "";
            this.isLogin = Boolean(localStorage.getItem("loginId")) || false;
            const obj = localStorage.getItem("groupIds");
            if (this._groupIds.length === 0 && obj) {
                this._groupIds = JSON.parse(obj) as any[];
            }
            this._currentGroupId = Number(localStorage.getItem("currentGroupId"));
            return true;
        }
        catch (ex) {
            console.error("localstorageのデータを取得できませんでした.");
            return false;
        }
    }

    /**
     * localstorage保存処理
     * @returns 保存に成功したかどうか
     */
    public saveStorage(): boolean{
        try{
            localStorage.setItem("token", this.token);
            localStorage.setItem("refreshToken", this.refreshToken);
            localStorage.setItem("expiresIn", this.expiresIn.toString());
            localStorage.setItem("memberName", this.memberName);
            localStorage.setItem("memberId", this.memberId.toString());
            localStorage.setItem("loginId", this.loginId);
            localStorage.setItem("isLogin", this.isLogin ? "true" : "");
            localStorage.setItem("groupIds", JSON.stringify(this.groupIds));
            localStorage.setItem("currentGroupId", this.currentGroupId.toString());
            return true;
        }
        catch (ex) {
            console.error("localstorage書き込みに失敗しました。", ex);
            return false;
        }
    }

    /**
     * ログイン情報を取得し強制ログインします.
     * @param baseToken 強制ログイン情報
     */
    public forceLogin(baseToken: any): boolean {
        try {
            const tokenData = baseToken.token;
            const memberData = baseToken.member;
            this.storeUserInfo(tokenData, memberData);
            this.isLogin = true;
            return true;
        }
        catch (ex) {
            console.error("ログインに失敗しました。", ex);
        }
        return false;
    }

    /**
     * ログイン情報を取得しログインします.
     * @param body ログイン情報
     */
    public async login(loginId: string, password: string): Promise<boolean> {
        try {
            const response = await axios.post<SuccessResponse<any>>("/api/auth", {
                loginId,
                password
            });
            if (response.data.status) {
                const data = response.data.data;
                this.loginId = loginId;
                this.isLogin = true;
                this.storeUserInfo(data.token, data.member);
                this.saveStorage();
                return true;
            }
        }
        catch (ex) {
            console.error("ログインに失敗しました。", ex);
        }
        return false;
    }

    /**
     * ログイン情報をセットしローカルストレージへ保存します.
     * @param data ログイン情報
     */
    public storeUserInfo(tokenInfo: any, memberInfo?: any) {
        const token = tokenInfo.accessToken as string | undefined;
        if (token) {
            this.token = token;
        }

        const refreshToken = tokenInfo.refreshToken as string | undefined;
        if (refreshToken) {
            this.refreshToken = refreshToken;
        }

        const expiresIn = tokenInfo.expiresAt as string | undefined;
        if (expiresIn) {
            this.expiresIn = new Date(expiresIn.replace(/-/g, "/"));
        }

        // member info
        if (memberInfo) {
            const memberId = Number(memberInfo.id ? memberInfo.id : 0);
            if (memberId) {
                this.memberId = memberId;
            }

            const memberName = memberInfo.name as string | undefined;
            if (memberName) {
                this.memberName = memberName;
            }

            const loginId = memberInfo.loginId as string | undefined;
            if (loginId) {
                this.loginId = loginId;
            }

            const groupIds = memberInfo.groups as { id: number, name: string }[] | undefined;
            if (groupIds) {
                this.groupIds = groupIds;

                // 存在しないグループを選択していたら書き換える
                if (!Enumerable
                    .from(groupIds)
                    .select(x => x.id)
                    .contains(this.currentGroupId) &&
                    groupIds.length > 0) {
                    this.currentGroupId = Number(groupIds[groupIds.length - 1].id);
                }
            }
        }
    }
    // #endregion
}

import { IDocument } from "./IDocument";
import { IDocumentPage } from "./IDocumentPage";
import { DateTime } from "luxon";

/**
 * 資料を表すためのメカニズムを提供します．
 */
export class Document<Page> implements IDocument<Page> {
    /**
     * 資料ID
     */
    public docId = "";

    /**
     * 資料名
     */
    public name = "";

    /**
     * サムネイルURL
     */
    public thumbnailUrl = "";

    /**
     * オーナーID
     */
    public ownedBy = 0;

    /**
     * パブリックかどうか
     */
    public isPublic = true;

    /**
     * パスワードが存在するかどうか
     */
    public hasPassword = true;

    /**
     * ページ一覧
     */
    public pages: Page[] = [];

    /**
     * 作成日
     */
    public createdAt: string = DateTime.local().toString();

    /**
     * 作成者
     */
    public createdBy = 0;

    /**
     * 更新日
     */
    public updatedAt: string = DateTime.local().toString();

    /**
     * 更新者
     */
    public updatedBy = 0;

    /**
     * コンストラクタ
     * @param document 初期値
     * @param pages ページ一覧
     */
    public constructor(document?: Partial<Document<any>>, pages?: Page[]) {
        Object.assign(this, document);
        if (pages) {
            this.pages = pages;
        }
    }
}
